.menu-type-area {
  padding: 60px 0;
}

.menu-type-item {
  text-align: center;
  padding: 10px;
}

.menu-type-item .thumb img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.menu-type-item .info img {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
}

.slick-prev,
.slick-next {
  color: #fff;
  z-index: 1;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  background: white;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.menu-type-item {
  text-align: center;
  padding: 10px;
  cursor: pointer;
}

.menu-type-item .thumb img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.menu-type-item .info img {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
}

.slick-prev,
.slick-next {
  color: #fff;
  z-index: 1;
}

@media (max-width: 768px) {
  .menu-type-area {
    padding: 30px 0;
  }
}

@media (max-width: 768px) {
  .menu-type-area {
    padding: 30px 0;
  }
}
