/* ----------------------------------

Template Name: Restan - Food & Restaurant HTML Template
Author: validtheme
Description:
Version: 1.0

Main Font    : Marcellus
Main Color   : #826a45

-------------------------------------

[Typography]

Body copy:    15px 'Marcellus', sans-serif
Header:     36px 'Marcellus', sans-serif
Input, textarea:  16px 'Marcellus', sans-serif
Sidebar heading:  22px 'Marcellus', sans-serif

>>> TABLE OF CONTENTS:
=======================
        
    01. Template default css
        - Animations
        - General
        - Section Title
        - Video Button
        - Typography
        - Tables
        - Forms
        - Buttons
        - Pagination
        - Colors
        - Accordion
        - Tabs
    02. Preloader
    03 Navbar
        - Navbar Default
        - Navbar Box
        - Navbar Sticky
    04. Banner
        - Banner Slide
        - Banner Zoom
	05. About
        - Version One
        - Version Two
    06. Food Menu
        - Style One
        - Style Two
        - Style Three
    07. Gallery
        - Gallery Slider
        - Gallery Grid
    08. Chef
        - Chef Version One
        - Chef Single
	09. Reservation
        - Style One
        - Style Two
    10. Testimonials
    11. Partners
    12. Opening Hours
    13. Blog
        - Standard
        - Grid
        - Left Sidebar
        - Right Sidebar
        - Single
    14. Error 404
    15. Footer
    16. PHP Contact Form
    17. Others

*/

@import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Marcellus&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");

/* Varialbes */
:root {
  --font-default: "Lato", sans-serif;
  --font-heading: "Marcellus", cursive;
  --font-optional: "Dancing Script", cursive;
  --fontawesome: "Font Awesome 5 Pro";
  --black: #000000;
  --dark: #1b1b1b;
  --dark-secondary: #262525;
  --white: #ffffff;
  --color-primary: #826a45;
  --color-secondary: #e7272d;
  --color-optional: #cf820a;
  --color-heading: #04000b;
  --color-paragraph: #666666;
  --box-shadow-primary: -1px 3px 10px 0 rgba(0, 0, 0, 0.6);
  --box-shadow-secondary: 0 10px 30px 0 rgba(44, 130, 237, 0.4);
  --box-shadow-regular: 0px 2px 12px 0px #e7e7e7;
  --bg-gray: #ebe9e6;
  --bg-gradient: linear-gradient(45deg, var(--color-primary) 30%, #ffb800 100%);
}

/* ============================================================== 
     # Reset Browsers
=================================================================== */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

html,
body,
div,
span,
img,
ol,
ul,
li,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
option,
legend,
label,
article,
aside,
caption,
figure,
footer,
header,
hgroup,
mark,
nav,
section,
time,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

.wrapper {
  height: 100%;
}

img {
  border: none;
  outline: none;
  max-width: 100%;
}

label {
  display: inline-block;
  font-weight: normal;
  margin-bottom: 5px;
  max-width: 100%;
}

a {
  outline: none;
  text-decoration: none;
  color: var(--color-heading);
}

a img {
  border: none;
}

a:active {
  outline: none;
  text-decoration: none;
  color: var(--color-heading);
  opacity: 1;
}

a:focus {
  outline: none;
  text-decoration: none;
  color: var(--color-heading);
}

a:hover {
  outline: none;
  text-decoration: none;
  color: var(--color-primary);
  opacity: 1;
}

.color-style-two a:hover {
  color: var(--color-secondary);
}

.secondary a:hover {
  color: var(--color-primary);
}

button {
  outline: medium none;
}

iframe {
  border: none;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid #eeeeee;
}

pre {
  display: block;
  margin: 0 0 30px;
  padding: 9.5px;
  word-wrap: break-word;
  word-break: break-all;
  color: #333333;
  border: 1px solid #ededed;
  border-radius: inherit;
  background-color: #f9f9f9;
  font-size: 13px;
  line-height: 1.42857143;
}

input {
  border: 1px solid #e7e7e7;
  border-radius: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  min-height: 50px;
}

input:focus {
  outline: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

textarea:focus {
  outline: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

select:focus {
  outline: none;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
}

ul {
  list-style-type: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 600;
}

.row {
  --bs-gutter-x: 30px;
}

/* ============================================================== 
    # Template Default Styles
=================================================================== */

body {
  font-family: var(--font-default);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  overflow-x: hidden;
  background-color: var(--white);
  color: var(--color-paragraph);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-heading);
  font-weight: 600;
  line-height: 1.2;
  font-family: var(--font-heading);
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 15px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

a,
.btn,
button {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  outline: medium none;
  text-decoration: none;
  font-weight: 600;
}

p {
  color: var(--color-paragraph);
  margin: 0 0 25px;
  text-transform: none;
  font-weight: 500;
  font-family: var(--font-default);
}

/* ============================================================== 
    # Helper CSS
=================================================================== */

.container-full {
  padding: 0 15px;
  margin: auto;
  max-width: 1400px;
}

@media (min-width: 576px) {
  .container-full {
    max-width: 540px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container-full {
    max-width: 720px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .container-full {
    max-width: 960px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container-full {
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .box-layout {
    width: 94%;
    margin: auto;
    border-radius: 30px;
  }
}

.container-fill {
  padding: 0 15px;
  margin: auto;
  max-width: 100%;
}

@media (min-width: 576px) {
  .container-fill {
    max-width: 540px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container-fill {
    max-width: 720px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .container-fill {
    max-width: 960px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .container-fill {
    width: 100%;
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1199px) {
  .container-fill {
    padding: 0 15px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .row {
    --bs-gutter-x: 1.5rem;
  }
}

.item-center {
  display: flex;
  align-items: center;
}

.bg-cover {
  background-position: center center !important;
  background-size: cover !important;
}

.bg-fixed {
  background-attachment: fixed !important;
  background-position: center center !important;
  background-size: cover !important;
}

.bg-fit {
  background-size: 100% 100% !important;
  background-position: center !important;
}

.bg-gray {
  background: var(--bg-gray);
}

.bg-dark .bg-gray {
  background: var(--dark-secondary);
}

.bg-gray-secondary {
  background: var(--bg-gray-secondary);
}

.bg-light {
  background-color: var(--white) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.bg-dark-secondary {
  background-color: var(--dark-secondary) !important;
}

.bg-theme {
  background-color: var(--color-primary);
}

.color-style-two .bg-theme {
  background-color: var(--color-secondary);
}

.bg-gradient {
  background-color: var(--color-primary);
  background: var(--bg-gradient) !important;
}

.bg-gradient-secondary {
  background-color: var(--color-primary);
  background: var(--bg-gradient-reverse) !important;
}

.text-gradient {
  background: var(--text-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  display: inline-block;
}

.text-light {
  color: var(--white);
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light p,
.text-light span,
.text-light a {
  color: var(--white);
}

.shadow {
  -webkit-box-shadow: inherit !important;
  box-shadow: inherit !important;
}

.shadow.dark {
  position: relative;
  z-index: 1;
}

.shadow.dark:after {
  background: var(--black);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.shadow.dark-hard {
  position: relative;
  z-index: 1;
}

.shadow.dark-hard:after {
  background: var(--black) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.shadow.theme {
  position: relative;
  z-index: 1;
}

.shadow.theme:after {
  background: var(--color-primary) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.shadow.light {
  position: relative;
  z-index: 1;
}

.shadow.light:after {
  background: var(--white) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.default-padding,
.default-padding-top,
.default-padding-bottom,
.default-padding-mx {
  position: relative;
}

.default-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .default-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.default-padding-big {
  padding: 250px 0;
}

@media only screen and (max-width: 767px) {
  .default-padding-big {
    padding: 60px 0;
  }
}

.default-padding-top {
  padding-top: 120px;
}

@media only screen and (max-width: 767px) {
  .default-padding-top {
    padding-top: 60px;
  }
}

.default-padding-bottom {
  padding-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .default-padding-bottom {
    padding-bottom: 60px;
  }
}

.default-padding.bottom-less {
  padding-top: 120px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 767px) {
  .default-padding.bottom-less {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.default-padding-bottom.bottom-less {
  margin-bottom: -30px;
}

@media only screen and (max-width: 767px) {
  .default-padding-bottom.bottom-less {
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}

.default-padding-top.bottom-less {
  margin-bottom: -30px;
}

@media only screen and (max-width: 767px) {
  .default-padding-top.bottom-less {
    margin-bottom: -20px;
  }
}

@media only screen and (min-width: 1200px) {
  .align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

.border-top {
  border-top: 1px solid #e7e7e7;
}

.border-bottom {
  border-bottom: 1px solid #e7e7e7;
}

.border-left {
  border-left: 1px solid #e7e7e7;
}

.border-right {
  border-right: 1px solid #e7e7e7;
}

.heading {
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: -5px;
  font-size: 42px;
  line-height: 1.3;
}

.heading span {
  font-weight: 400;
}

.site-heading {
  margin-bottom: 60px;
}

.site-heading strong {
  display: inline-block;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.site-heading strong::after {
  position: absolute;
  left: 0;
  bottom: -5px;
  content: "";
  height: 100%;
  width: 100%;
  /* background: url(../img/shape/35.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: -1;
}

.title {
  font-weight: 600;
  font-size: 50px;
  margin-top: -15px;
}

@media (max-width: 767px) {
  .title {
    font-size: 36px;
    margin-top: 0;
  }
}

.bg-dark .title {
  color: var(--white);
}

.site-heading .title {
  margin-bottom: 0;
}

.bg-dark .site-heading .title {
  color: var(--white);
}

.sub-title {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 600;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  display: inline-block;
  font-size: 20px;
  padding: 0 10px;
}

.color-style-two .sub-title {
  color: var(--color-secondary);
}

.sub-title::after {
  position: absolute;
  top: 50%;
  right: 100%;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/shape/17.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: right center;
  margin-top: -13px;
}

.color-style-two .sub-title::after {
  background: url(../img/shape/17-2.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: right center;
}

.sub-title::before {
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/shape/18.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: left center;
  margin-top: -13px;
}

.color-style-two .sub-title::before {
  background: url(../img/shape/18-2.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: left center;
}

.text-light .sub-title::after,
.text-light .sub-title::before,
.text-light.sub-title::after,
.text-light.sub-title::before {
  filter: brightness(0) invert(1);
}

.sub-title img {
  height: 13px;
}

.sub-title img:first-child {
  margin-right: 10px;
}

.sub-title img:last-child {
  margin-left: 10px;
}

@media (max-width: 767px) {
  .sub-title {
    margin-bottom: 10px;
  }
}

.site-heading p {
  margin-bottom: 0;
  margin-top: 25px;
  padding: 0 10%;
}

.site-heading .devider {
  display: inline-block;
  width: 50px;
  height: 2px;
  background: var(--color-primary);
  position: relative;
  z-index: 1;
  left: 10px;
}

.site-heading.light .devider,
.bg-theme .site-heading .devider,
.bg-gradient .site-heading .devider,
.shadow .site-heading .devider,
.bg-dark .site-heading .devider {
  background: var(--white);
}

@media (max-width: 767px) {
  .site-heading p {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .site-heading {
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 1.2;
  }
}

.heading-left {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.heading-left .heading {
  font-weight: 600;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .heading-left .heading {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .heading-left .heading {
    margin-bottom: 15px;
    font-size: 36px;
    line-height: 1.2;
  }
}

.heading-left p {
  margin-bottom: 0;
}

.heading-left .btn {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .heading-left {
    margin-bottom: 30px;
  }
}

.sub-heading {
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 600;
  display: inline-block;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  font-size: 20px;
  padding-right: 10px;
}

.color-style-two .sub-heading {
  color: var(--color-secondary);
}

.color-style-two .text-light .sub-heading {
  color: var(--white);
}

.sub-heading::before {
  position: absolute;
  top: 50%;
  left: 100%;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/shape/18.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: left center;
  margin-top: -13px;
}

.color-style-two .sub-heading::before {
  background: url(../img/shape/18-2.png);
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: left center;
}

.text-light .sub-heading::before {
  filter: brightness(0) invert(1);
}

.shadow .sub-heading {
  -webkit-text-fill-color: var(--white);
  font-weight: 600;
}

.bg-gradient .sub-heading {
  color: var(--white);
  background: transparent;
  -webkit-background-clip: inherit;
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
  opacity: 0.9;
}

.site-heading.text-light .sub-title,
.site-heading.text-light .sub-heading {
  -webkit-text-fill-color: inherit;
  color: var(--white);
}

/* ============================================================== 
    # Video Play Button 
=================================================================== */
.video-play-button i {
  display: inline-block;
  background: var(--color-primary);
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  position: relative;
}

.video-play-button i {
  font-weight: 100;
  font-size: 35px;
  position: relative;
  left: 3px;
  top: 2px;
}

.video-play-button .effect {
  position: absolute;
  width: 150px;
  height: 150px;
  background: #07071e;
  opacity: 0;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  z-index: -1;
  -webkit-animation: video-play 1500ms ease-out infinite;
  animation: video-play 1500ms ease-out infinite;
}

.video-play-button .effect::after {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.2);
  opacity: 0;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  -webkit-animation: video-play 1500ms ease-out infinite;
  animation: video-play 1500ms ease-out infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.video-play-button .effect::before {
  position: absolute;
  content: "";
  width: 150px;
  height: 150px;
  background: var(--color-primary);
  opacity: 0;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  -webkit-animation: video-play 1500ms ease-out infinite;
  animation: video-play 1500ms ease-out infinite;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.color-style-two .video-play-button .effect::before {
  background: var(--color-secondary);
}

.video-play-button.with-text {
  width: auto;
  background: transparent;
}

.video-play-button.with-text span {
  position: relative;
  z-index: 1;
}

.video-play-button.with-text span i {
  margin-left: 25px;
  margin-right: 30px;
  color: var(--white);
  left: -1px;
}

.video-play-button.with-text .effect {
  height: 60px;
  width: 60px;
  left: 0;
  -webkit-animation: inherit;
  animation: inherit;
  background: var(--color-primary);
  top: 0;
  opacity: 1;
  z-index: 1;
}

.color-style-two .video-play-button.with-text .effect {
  background: var(--color-secondary);
}

.video-play-button.with-text .effect::before {
  display: none;
}

.video-play-button.with-text .effect::after {
  background: var(--color-primary);
  height: 100%;
  width: 100%;
  z-index: inherit;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.color-style-two .video-play-button.with-text .effect::after {
  background: var(--color-secondary);
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/* ============================================================== 
    # Template Animation
=================================================================== */

/* Video Paly */
@-webkit-keyframes video-play {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

@keyframes video-play {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/*Zoom Animation */
.zoom-animation {
  -webkit-animation: zoom 2000ms ease-out infinite;
  animation: zoom 2000ms ease-out infinite;
}

/* Slider Zoom Effect */
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.2, 1.2);
  }
}

@keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  to {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }
}

/* Spiner Animation */
.spiner-animation {
  -webkit-animation: spinner 20s infinite linear;
  animation: spinner 20s infinite linear;
}

/*Preloader Spiner Animation */
@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

/* Spiner Animation */
.spiner-reverse-animation {
  -webkit-animation: spinnerReverse 20s infinite linear;
  animation: spinnerReverse 20s infinite linear;
}

/*Preloader Spiner Animation */
@-webkit-keyframes spinnerReverse {
  to {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@keyframes spinnerReverse {
  to {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

/* Infinite Up Down Animation */
@keyframes UpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* Animation UpDown */
.updown-animation {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  animation-timing-function: cubic-bezier(0.54, 0.085, 0.5, 0.92);
  -webkit-animation-name: UpDown;
  animation-name: UpDown;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes UpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/* Animation Left Right */
.leftRight-animation {
  -webkit-animation: fadeLeftRight 10s ease-out infinite;
  animation: fadeLeftRight 10s ease-out infinite;
}

/* Fade Left Right */
@keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

@-webkit-keyframes fadeLeftRight {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}

/* Animation Right Left */
.rightLeft-animation {
  -webkit-animation: fadeRightLeft 10s ease-out infinite;
  animation: fadeRightLeft 10s ease-out infinite;
}

@keyframes fadeRightLeft {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

@-webkit-keyframes fadeRightLeft {
  0%,
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  50% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
  }
}

/* Animation Zoom Up Down */
.zoomUpDown-animation {
  -webkit-animation: zoomUpDown 10s ease-out infinite;
  animation: zoomUpDown 10s ease-out infinite;
}

/* Zoom Up Down */
@keyframes zoomUpDown {
  0%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

@-webkit-keyframes zoomUpDown {
  0%,
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

/* Animation Moving */
.moving-animation {
  -webkit-animation: moving 10s ease-out infinite;
  animation: moving 10s ease-out infinite;
}

/* Mooving Animation */
@-webkit-keyframes moving {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  20% {
    -webkit-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }

  50% {
    -webkit-transform: translate(-100px, -30px);
    transform: translate(-100px, -30px);
  }

  70% {
    -webkit-transform: translate(-100px, 0px);
    transform: translate(-100px, 0px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

@keyframes moving {
  0% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }

  20% {
    -webkit-transform: translate(0px, -30px);
    transform: translate(0px, -30px);
  }

  50% {
    -webkit-transform: translate(-100px, -30px);
    transform: translate(-100px, -30px);
  }

  70% {
    -webkit-transform: translate(-100px, 0px);
    transform: translate(-100px, 0px);
  }

  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}

/* Animation Rotation */
@keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes infinite-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Button Border Animation */
@-webkit-keyframes open {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    width: 100px;
    height: 0;
  }

  100% {
    width: 100px;
    height: 55px;
  }
}

@keyframes open {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    width: 100px;
    height: 0;
  }

  100% {
    width: 100px;
    height: 55px;
  }
}

@-webkit-keyframes openB {
  0% {
    width: 0px;
  }

  100% {
    width: 100px;
  }
}

@keyframes openB {
  0% {
    width: 0px;
  }

  100% {
    width: 100px;
  }
}

/* Animation Shine (A shine color will move inside the text) */

@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  60% {
    background-position: 180px;
  }

  100% {
    background-position: 180px;
  }
}

@-webkit-keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@keyframes infiniteMove {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

@-webkit-keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }

  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }

  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

@keyframes infiniteMove2 {
  0% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }

  50% {
    border-radius: 50% 60% 30% 60% / 30% 60% 70% 40%;
  }

  100% {
    border-radius: 60% 30% 70% 40% / 60% 40% 30% 70%;
  }
}

/* ============================================================== 
    # Button Styles
=================================================================== */
.btn {
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 0;
  border: 2px solid transparent;
  -webkit-box-shadow: inherit;
  box-shadow: none !important;
  font-size: 16px;
  letter-spacing: 0;
  padding: 13px 30px;
  background: #e7edf8;
  position: relative;
  z-index: 1;
}

.btn:focus,
.btn.active {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  border: 2px solid transparent;
}

.btn:hover {
  background-color: var(--color-primary);
  color: var(--white);
}

.btn.radius {
  border-radius: 30px;
}

.btn.btn-border {
  border: 2px solid #dddddd;
  background: transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-weight: 600;
}

.btn.btn-border::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.btn.btn-border:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-border:hover {
  border-color: var(--dark);
}

.btn.btn-border.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: #e7e7e7;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn.btn-border.animation:hover {
  border-color: #e7e7e7;
  color: var(--dark);
}

.btn.btn-border.animation:hover::after {
  width: 100%;
}

.btn.btn-border-dark {
  border: 2px solid var(--dark);
  background: transparent;
}

.btn-md.btn-border-dark {
  padding: 15px 35px;
}

.btn.btn-border-dark:hover {
  background: var(--dark);
  border-color: var(--dark);
}

.btn.btn-border-dark.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn.btn-border-dark.animation:hover {
  border-color: var(--dark);
  color: var(--white);
}

.btn.btn-border-dark.animation:hover::after {
  width: 100%;
}

.btn.btn-border-theme {
  border: 2px solid var(--color-primary);
  background: transparent;
}

.btn.btn-border-theme:hover {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.btn.btn-border-theme.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: var(--color-primary);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn.btn-border-theme.animation:hover {
  border-color: var(--color-primary);
  color: var(--white);
}

.btn.btn-border-theme.animation:hover::after {
  width: 100%;
}

.btn.btn-border-light {
  border: 2px solid var(--white);
  background: transparent;
  color: var(--white);
}

.btn.btn-border-light:hover {
  background: var(--white);
  border-color: var(--white);
  color: var(--dark);
}

.btn.btn-border-light.animation::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background: var(--white);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn.btn-border-light.animation:hover {
  border-color: var(--white);
  color: var(--dark);
}

.btn.btn-border-light.animation:hover::after {
  width: 100%;
}

.btn-md.btn-border-light {
  border: 1px solid var(--white);
  padding: 15px 35px;
}

.btn.btn-border.light {
  color: var(--white);
  border-color: var(--white);
}

.btn.btn-border.light::after {
  background: var(--color-primary);
}

.btn.btn-border.light:hover {
  border-color: var(--color-primary);
}

.btn.btn-gradient {
  border: none;
  color: var(--white);
}

.btn.btn-gradient::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: linear-gradient(
    to left,
    var(--color-primary),
    var(--color-secondary),
    var(--color-primary)
  );
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  background-size: 250%;
}

.btn.btn-gradient:hover::after {
  background-position: -70% 0;
}

.btn.btn-gradient.active {
  background-position: -70% 0;
}

.btn.btn-theme {
  color: var(--white);
  border: none;
  background: var(--color-primary);
}

.color-style-two .btn.btn-theme {
  background: var(--color-secondary);
}

.color-style-two .btn.btn-theme:hover {
  color: var(--white);
}

.btn.btn-theme::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.shadow .btn.btn-theme::after {
  background-color: var(--white);
}

.shadow .btn.btn-theme:hover {
  color: var(--color-heading);
}

.text-light .btn.btn-theme:hover {
  color: var(--color-heading);
}

.text-light .btn.btn-theme:after {
  background: var(--white);
}

.banner-style-four .btn.btn-theme::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background: var(--white) !important;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.btn.btn-theme:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.banner-style-four .btn.btn-theme:hover {
  color: var(--color-heading) !important;
}

button {
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 7px;
  border: 2px solid transparent;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  font-size: 16px;
  letter-spacing: 0;
  padding: 14px 40px;
  background: #e7edf8;
  position: relative;
  z-index: 1;
  color: var(--white);
  border: none;
  background: var(--color-primary);
}

.color-style-two button {
  background: var(--color-secondary);
}

button::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

button:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

button i {
  margin-right: 4px;
}

.btn.btn-theme.secondary {
  color: var(--white);
  border: none;
  background: var(--color-primary);
}

.btn.btn-theme.secondary::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.text-light .btn.btn-theme.secondary::after {
  background-color: var(--white);
}

.text-light .btn.btn-theme.secondary:hover {
  color: var(--color-heading);
}

.btn.btn-theme.secondary:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-theme.hover-light::after {
  background-color: var(--white);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn.btn-theme.hover-light:hover {
  color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.btn.btn-theme.hover-light:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-light {
  color: var(--color-heading);
  background: var(--white);
  border: 1px solid;
}

.btn.btn-light::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.btn.btn-light:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn.btn-light:hover {
  color: var(--white);
  border-color: transparent;
}

.btn.btn-dark {
  color: var(--white);
  background: var(--dark);
  border: none;
}

.btn.btn-dark::after {
  position: absolute;
  top: 0;
  right: inherit;
  bottom: inherit;
  left: 0;
  content: "";
  height: 100%;
  width: 0;
  z-index: -1;
  background-color: var(--color-primary);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.shadow .btn.btn-dark::after {
  background-color: var(--white);
}

.shadow .btn.btn-dark:hover {
  color: var(--color-heading) !important;
}

.btn.btn-dark:hover::after {
  width: 100%;
}

.btn.btn-dark.secondary {
  color: var(--white);
  border: none;
  background: var(--dark);
}

.btn.btn-dark.secondary::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--color-primary);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.btn.btn-dark.secondary:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.btn i {
  position: relative;
  top: 1px;
  margin-left: 5px;
}

.btn-md i {
  font-weight: 1;
  font-size: 24px;
  margin: 0;
  float: left;
  position: relative;
  top: 1px;
  margin-right: 10px;
}

.btn.text-slide {
  min-width: 150px;
  height: 55px;
  line-height: 55px;
}

.btn.text-slide span {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
}

.btn.animate-border {
  overflow: inherit;
  z-index: inherit;
  width: 200px;
  height: 55px;
  line-height: 55px;
  padding: 0;
}

.btn.animate-border:hover {
  background: transparent;
  color: var(--dark);
}

.btn.animate-border:hover .hover-border::after,
.btn.animate-border:hover .hover-border::before {
  opacity: 1;
  -webkit-animation: open 0.4s;
  /* Chrome, Safari, Opera */
  animation: open 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

.btn.animate-border:hover .hover-border-bottom::after,
.btn.animate-border:hover .hover-border-bottom::before {
  opacity: 1;
  -webkit-animation: openB 0.4s;
  /* Chrome, Safari, Opera */
  animation: openB 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-animation-direction: normal;
  animation-direction: normal;
}

.btn.animate-border .hover-border {
  position: absolute;
  left: 0;
  top: -1px;
  height: 100%;
  width: 100%;
}

.btn.animate-border .hover-border::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  display: block;
  opacity: 0;
  border-top: solid 2px var(--color-primary);
  border-left: solid 2px var(--color-primary);
  right: 98px;
  top: -1px;
}

.btn.animate-border .hover-border::after {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  display: block;
  opacity: 0;
  border-top: solid 2px var(--color-primary);
  border-right: solid 2px var(--color-primary);
  left: 98px;
  top: -1px;
}

.btn.animate-border .hover-border-bottom {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.btn.animate-border .hover-border-bottom::before {
  position: absolute;
  content: "";
  width: 0;
  display: block;
  opacity: 0;
  height: 55px;
  border-bottom: solid 2px var(--color-primary);
  right: -1px;
  bottom: -2px;
}

.btn.animate-border .hover-border-bottom::after {
  position: absolute;
  content: "";
  width: 0;
  display: block;
  opacity: 0;
  height: 100%;
  border-bottom: solid 2px var(--color-primary);
  left: 0;
  bottom: -2px;
}

.btn.animated-arrow {
  border: none;
  background: transparent;
  min-width: 14rem;
  height: auto;
  padding: 0;
}

.btn.animated-arrow .circle {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3.5rem;
  height: 3.5rem;
  background: var(--color-primary);
  border-radius: 2rem;
}

.btn.animated-arrow .circle .icon {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 15px;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.btn.animated-arrow .circle .icon::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid var(--white);
  border-right: 0.125rem solid var(--white);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn.animated-arrow .button-text {
  -webkit-transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 0;
  margin: 0 0 0 70px;
  color: var(--dark);
  line-height: 2;
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.btn.animated-arrow:hover {
  color: var(--white);
}

.btn.animated-arrow:hover .circle {
  width: 100%;
}

.btn.animated-arrow:hover .circle .icon.arrow {
  background: var(--white);
  -webkit-transform: translate(1.5rem, 0);
  transform: translate(1.5rem, 0);
}

.btn.animated-arrow:hover .button-text {
  color: var(--white);
}

.btn.circle {
  border-radius: 30px !important;
}

.btn-simple {
  line-height: 1;
  text-transform: uppercase;
  display: inline-block;
  color: var(--color-paragraph);
}

.btn-simple i {
  transform: rotate(-45deg);
  display: inline-block;
  margin-left: 2px;
}

.btn-simple:hover {
  color: var(--color-primary);
}

.video-btn i {
  display: inline-block;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  background: var(--color-primary);
  border-radius: 50%;
  position: relative;
  margin-right: 20px;
  color: var(--white);
}

.text-light .video-btn i {
  background: var(--white);
  color: var(--color-primary);
}

.video-btn i::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: var(--color-primary) repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
  z-index: -1;
}

.text-light .video-btn i::after {
  background: var(--white) repeat scroll 0 0;
}

.text-shine {
  background: linear-gradient(
    to right,
    var(--dark) 0,
    var(--white) 10%,
    var(--color-primary) 20%
  );
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: shine 3s infinite linear;
  animation: shine 3s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  padding: 12px 48px;
}

.animate-inout {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8a6552),
    to(#472d20)
  );
  background: linear-gradient(90deg, #8a6552 0%, #472d20 100%);
  color: var(--white);
  font-size: 12px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: solid 2px var(--color-primary);
  cursor: pointer;
}

.btn-icon {
  position: relative;
  padding-left: 20px;
}

.btn-icon:hover {
  color: var(--color-primary);
}

.btn-icon::after {
  position: absolute;
  right: 10px;
  top: 50%;
  content: "";
  height: 2px;
  left: 0;
  background: var(--color-heading);
  transform: translateY(-50%);
  margin-top: -1px;
  transition: all 0.35s ease-in-out;
}

.btn-icon:hover::after {
  background: var(--color-primary);
}

.btn-icon i {
  font-weight: 600;
}

.btn-icon:hover {
  padding-left: 40px;
}

.animate-inout span {
  z-index: 1;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  line-height: 20px;
}

.animate-inout::before,
.animate-inout::after {
  width: 0%;
  height: 0%;
  position: absolute;
  content: "";
  border-radius: 100%;
}

.animate-inout:after {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: var(--white);
}

.animate-inout:before {
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  background: var(--color-primary);
}

.animate-inout.animate-inout-borde {
  background: transparent;
  color: var(--dark);
}

.animate-inout.animate-inout-borde::after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#8a6552),
    to(#472d20)
  );
  background: linear-gradient(90deg, #8a6552 0%, #472d20 100%);
}

.animate-inout.animate-inout-borde:hover {
  color: var(--white);
}

.animate-inout.animate-inout-borde:hover::before {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.animate-inout.animate-inout-borde:hover::after {
  -webkit-transition: all 0.7s ease-in;
  transition: all 0.7s ease-in;
}

.animate-inout:hover {
  color: var(--dark);
}

.animate-inout:hover::before,
.animate-inout:hover::after {
  width: 200px;
  height: 200px;
  border-radius: 4px;
}

.animate-inout:hover::before {
  -webkit-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

.animate-inout:hover::after {
  -webkit-transition: all 0.7s ease-in;
  transition: all 0.7s ease-in;
}

.btn-md {
  padding: 16px 35px;
  font-size: 16px;
}

.btn-md.btn-border {
  padding: 14px 52px;
}

.btn-sm {
  padding: 16px 45px;
  font-size: 14px;
}

.btn-sm.btn-border,
.btn-sm.btn-light {
  padding: 14px 45px;
}

.mfp-close-btn-in .mfp-close::after {
  display: none;
}

/* Btn Animation */

.btn-animation {
  z-index: 1;
  position: relative;
  font-size: 18px;
  display: inline-block;
  margin-left: 10px;
}

.bg-dark .btn-animation {
  color: var(--white);
}

.btn-animation i {
  display: inline-block;
  height: 55px;
  width: 55px;
  text-align: center;
  line-height: 55px;
  background: var(--white);
  color: var(--color-heading);
  border-radius: 50%;
  font-weight: 400;
  margin-right: 10px;
  transition: all 0.35s ease-in-out;
  transform: scale(0);
}

.btn-animation:hover {
  margin-left: 0;
  color: var(--color-heading);
}

.bg-dark .btn-animation:hover {
  color: var(--white);
}

.btn-animation:hover i {
  transform: scale(1);
}

.btn-animation::after {
  position: absolute;
  left: -13px;
  top: 0;
  content: "";
  height: 60px;
  width: 60px;
  border: 1px solid #c2bfbf;
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
  transform: scale(1);
  z-index: -1;
}

.bg-dark .btn-animation::after {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.btn-animation:hover::after {
  transform: scale(0);
}

.btn-animation span {
  transition: all 0.35s ease-in-out;
  left: -50px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
}

.btn-animation:hover span {
  left: 0;
}

.btn-animation.dark::after {
  border-color: #c5c5c5;
  z-index: -1;
}

.btn-animation.dark i {
  background: var(--color-primary);
  color: var(--white);
}

.btn-animation.dark:hover {
  color: var(--color-heading);
}

@media only screen and (max-width: 767px) {
  .row {
    --bs-gutter-x: 1.5rem;
  }
}

a.btn-round-animation {
  display: inline-block;
  height: 180px;
  width: 180px;
  text-align: center;
  line-height: 180px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

a.btn-round-animation i {
  display: inline-block;
  transform: rotate(-45deg);
  font-weight: 400;
  margin-left: 5px;
}

a.btn-round-animation::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 0;
  width: 100%;
  background: var(--color-primary);
  z-index: -1;
  transition: all 0.3s ease-in-out;
  border-radius: 50%;
}

a.btn-round-animation::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 0;
  width: 100%;
  background: var(--bg-gradient);
  z-index: -1;
  transition: all 0.4s ease-in-out;
  border-radius: 50%;
}

a.btn-round-animation:hover {
  color: var(--white);
}

a.btn-round-animation:hover::before {
  height: 100%;
}

a.btn-round-animation:hover::after {
  opacity: 0.4;
  height: 80%;
}

a.btn-round-animation.dark {
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark,
.bg-dark-secondary,
.bg-dark p,
.bg-dark-secondary p {
  color: #cccccc;
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6 {
  color: var(--white);
}

/* ============================================================== 
    # Topbar Styles
=================================================================== */

.item-flex {
  align-items: center;
  display: flex;
}

.item-flex li {
  margin-left: 30px;
}

.item-flex li:first-child {
  margin: 0;
}

.item-flex li i {
  margin-right: 10px;
  font-weight: 100;
  font-size: 24px;
  color: var(--color-primary);
}

.bg-theme .item-flex li i {
  color: var(--white);
}

.top-bar-area li {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.top-bar-area li a {
  font-weight: 500;
}

.top-bar-area .social li {
  display: inline-block;
  margin-right: 30px;
}

.top-bar-area .social li:first-child {
  margin-right: 0;
}

.top-bar-area .text-end .social li {
  margin-right: 0;
  margin-left: 20px;
}

.top-bar-area .text-end .social li:first-child {
  margin-left: 0;
}

.top-bar-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.top-bar-area.top-bar-style-one.bg-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.top-bar-area li img {
  height: 23px;
  margin-right: 5px;
}

.text-end .item-flex {
  justify-content: right;
}

.text-end .item-flex i {
  font-size: 18px;
  margin: 0;
}

.text-end .item-flex .dropdown {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  margin-right: 30px;
}

.text-end .item-flex .dropdown button {
  background: transparent;
  padding: 0 30px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.text-end .item-flex .dropdown button img {
  height: 27px;
  border: 1px solid;
  border-radius: 50%;
  margin-right: 5px;
}

.text-end .item-flex .dropdown button::after {
  display: none;
}

.text-end .item-flex .dropdown ul.dropdown-menu {
  padding: 15px;
  min-width: 200px;
  top: 5px !important;
  border: none;
}

.text-end .item-flex .dropdown ul.dropdown-menu a.dropdown-item {
  color: var(--color-heading);
  background: transparent;
}

.text-end .item-flex .dropdown ul.dropdown-menu li {
  margin: 0;
}

.text-end .item-flex .dropdown button i {
  font-size: 16px;
  margin-left: 5px;
}

.topbar-two-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topbar-two-items img {
  height: 60px;
}

.topbar-two-items .topbar-info {
  display: flex;
  align-items: center;
}

.topbar-two-items .topbar-info i {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin-right: 15px;
  font-size: 25px;
}

.topbar-two-items .topbar-info h5 {
  margin-bottom: 3px;
  font-family: var(--font-default);
  font-size: 18px;
}

.topbar-two-items .topbar-info p {
  margin: 0;
}

.topbar-two-items .topbar-info a,
.topbar-two-items .topbar-info p {
  color: var(--font-heading);
  font-weight: 600;
}

.topbar-two-items .logo a {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  display: block;
  padding: 20px 50px;
}

.top-bar-style-two {
  padding-bottom: 30px;
}

.top-bar-area p {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: right;
}

.top-bar-area p i {
  font-weight: 100;
  font-size: 20px;
  margin-right: 10px;
}

@media (min-width: 1024px) {
  .top-bar-area.top-bar-style-one.bg-transparent {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 8;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .bg-transparent .item-flex li i {
    color: var(--white);
  }

  .bg-transparent .text-end .item-flex .dropdown {
    border-color: rgba(255, 255, 255, 0.4);
  }
}

/* ============================================================== 
    # Navbar Styles
=================================================================== */
@media (min-width: 1024px) {
  nav.navbar.navbar-style-one.no-background::before {
    position: absolute;
    left: 0;
    content: "";
    height: 100%;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(20px);
    width: 100%;
    z-index: -1;
  }
}

@media (min-width: 1200px) {
  nav.navbar.navbar-style-one.no-background a.navbar-brand {
    position: relative;
    z-index: 1;
    padding: 0 35px;
    top: 15px;
  }

  nav.navbar.navbar-style-one a.navbar-brand {
    transition: inherit;
  }

  nav.navbar.navbar-style-one.no-background a.navbar-brand::after {
    position: absolute;
    left: 0;
    top: -60px;
    content: "";
    height: 160px;
    width: 100%;
    background: var(--color-primary);
    z-index: -1;
  }

  nav.navbar.navbar-style-one.no-background a.navbar-brand::before {
    position: absolute;
    right: -23px;
    bottom: -40px;
    content: "";
    border-bottom: 31px solid transparent;
    border-left: 23px solid var(--color-primary);
    opacity: 0.8;
  }

  nav.navbar.navbar-style-one.no-background::after {
    position: absolute;
    left: 10%;
    content: "";
    height: 100%;
    right: 0;
    bottom: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    z-index: -1;
  }

  nav.navbar.navbar-style-one.no-background::before {
    position: absolute;
    left: 10%;
    content: "";
    height: 100%;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(20px);
    width: 100%;
    z-index: -1;
  }
}

/* ============================================================== 
    # Nice Select Styles
=================================================================== */
.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: var(--white);
  border: solid 1px #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 16px;
  font-weight: normal;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;
}

.nice-select:hover {
  border-color: #dbdbdb;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #999999;
}

.nice-select::after {
  border-bottom: 2px solid #999999;
  border-right: 2px solid #999999;
  content: "";
  display: block;
  height: 5px;
  margin-top: -2px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  overflow-y: auto !important;
  height: auto;
}

.nice-select.open ::after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999999;
  pointer-events: none;
}

.nice-select.disabled::after {
  border-color: #96aac1;
}

.nice-select.wide {
  width: 100%;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small::after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: var(--white);
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: 600;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}

.mfp-iframe-holder .mfp-close {
  color: transparent;
  background: transparent;
}

.mfp-iframe-holder .mfp-close::before {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  z-index: 1;
  display: block;
  content: "\f00d";
  font-family: "Font Awesome 5 Pro";
  font-weight: 100;
  color: var(--white);
}

/* ============================================================== 
    # Banner Styles
=================================================================== */
.banner-area {
  height: 100%;
  width: 100%;
  position: relative;
}

@media (max-width: 1023px) {
  .banner-area {
    height: auto;
  }
}

.banner-area div {
  height: 100%;
}

.banner-area div.swiper-slide .row div {
  height: auto;
}

.banner-area.top-pad-80 .content {
  padding-top: 80px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-80 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-80 .content {
    padding-top: 140px;
  }
}

.banner-area.top-pad-90 .content {
  padding-top: 90px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-90 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-90 .content {
    padding-top: 140px;
  }
}

.banner-area.top-pad-100 .content {
  padding-top: 100px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-100 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-100 .content {
    padding-top: 140px;
  }
}

.banner-area.top-pad-110 .content {
  padding-top: 110px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-110 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-110 .content {
    padding-top: 140px;
  }
}

.banner-area.top-pad-120 .content {
  padding-top: 120px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-120 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-120 .content {
    padding-top: 140px;
  }
}

.banner-area.top-pad-130 .content {
  padding-top: 130px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-130 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-130 .content {
    padding-top: 140px;
  }
}

.banner-area.top-pad-150 .content {
  padding-top: 150px;
}

@media (max-width: 1023px) {
  .banner-area.top-pad-150 .content {
    padding-top: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area.top-pad-150 .content {
    padding-top: 140px;
  }
}

.banner-area.auto-height {
  height: auto;
}

.banner-area.auto-height div {
  height: auto;
}

.banner-area.auto-height .content {
  padding: 200px 0;
}

@media only screen and (max-width: 767px) {
  .banner-area.auto-height .content {
    padding: 60px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.auto-height .content {
    padding: 120px 0;
  }
}

.banner-area.auto-height .content .thumb {
  padding-left: 35px;
}

@media (max-width: 991px) {
  .banner-area.auto-height .content .thumb {
    padding-left: 0;
    margin-top: 50px;
  }
}

.banner-area.auto-height.inc-header-transparent .content {
  padding-top: 250px;
}

@media only screen and (max-width: 767px) {
  .banner-area.auto-height.inc-header-transparent .content {
    padding-top: 140px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.auto-height.inc-header-transparent .content {
    padding-top: 220px;
  }
}

.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
  .content
  .info {
  padding-top: 80px;
}

.banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
  .content
  .thumb {
  margin-top: 250px;
}

@media only screen and (max-width: 767px) {
  .banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
    .content
    .thumb {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.auto-height.inc-header-transparent.double-items.bottom-thumb
    .content
    .thumb {
    margin-top: 50px;
  }
}

.banner-area .content {
  position: relative;
  z-index: 9;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (max-width: 1023px) {
  .banner-area .content {
    padding: 120px 0;
  }
}

@media only screen and (max-width: 767px) {
  .banner-area .content {
    padding: 60px 0;
  }
}

.banner-area h4 {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translate3d(-15%, 0, 0);
  transform: translate3d(-15%, 0, 0);
  opacity: 0;
  visibility: hidden;
}

.banner-area h2 {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}

.banner-area p,
.banner-area ul {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  padding-right: 32%;
  margin: 0;
  visibility: hidden;
}

@media (max-width: 1023px) {
  .banner-area p {
    padding-right: 0;
  }
}

.banner-area.text-center p {
  padding-left: 13%;
  padding-right: 13%;
}

@media (max-width: 1023px) {
  .banner-area.text-center p {
    padding: 0;
  }
}

.banner-area .thumb {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 0;
  visibility: hidden;
}

.banner-area .button {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  visibility: hidden;
  margin-top: 30px;
}

.banner-area .banner-slide h4 {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.banner-area .banner-slide h2 {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.banner-area .banner-slide p,
.banner-area .banner-slide ul {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.banner-area .banner-slide .button {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active h4 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active h2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1400ms;
  transition-delay: 1400ms;
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active p,
.banner-area .banner-slide .swiper-slide.swiper-slide-active ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

.banner-area .banner-slide .swiper-slide.swiper-slide-active .button {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.banner-area.double-items .thumb {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.banner-area.double-items .swiper-slide.swiper-slide-active .thumb {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
  visibility: visible;
  opacity: 1;
}

.banner-area.double-items.bottom-thumb .content {
  padding: 0;
}

.banner-area.double-items.bottom-thumb .content .thumb {
  margin-top: 120px;
}

@media only screen and (max-width: 767px) {
  .banner-area.double-items.bottom-thumb .content {
    padding: 50px 0;
  }

  .banner-area.double-items.bottom-thumb .content .thumb {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.double-items.bottom-thumb .content {
    padding: 120px 0;
  }

  .banner-area.double-items.bottom-thumb .content .thumb {
    margin-top: 50px;
  }
}

.banner-area.zoom-effect .banner-thumb {
  -webkit-transition: 10s ease-out;
  transition: 10s ease-out;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
}

.banner-area.zoom-effect .swiper-slide.swiper-slide-active .banner-thumb {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.banner-area .swiper-notification {
  display: none;
}

.banner-area .swiper-slide.swiper-slide-active h4 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 800ms;
  transition-delay: 800ms;
}

.banner-area .swiper-slide.swiper-slide-active h2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 1600ms;
  transition-delay: 1600ms;
}

.banner-area .swiper-slide.swiper-slide-active p,
.banner-area .swiper-slide.swiper-slide-active ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 2400ms;
  transition-delay: 2400ms;
}

.banner-area .swiper-slide.swiper-slide-active .button {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 3200ms;
  transition-delay: 3200ms;
}

.banner-area.banner-style-two .swiper-slide.swiper-slide-active .button {
  -webkit-transition-delay: 2400ms;
  transition-delay: 2400ms;
}

.banner-area .banner-items {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.banner-area:hover .swiper-button-prev,
.banner-area:hover .swiper-button-next {
  opacity: 1;
}

.banner-area:hover .swiper-button-prev {
  left: 30px;
  right: auto;
}

.banner-area:hover .swiper-button-next {
  right: 30px;
  left: auto;
}

.banner-area .swiper-button-prev,
.banner-area .swiper-button-next {
  height: auto;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  opacity: 0;
}

.banner-area .swiper-button-prev::after,
.banner-area .swiper-button-next::after {
  font-size: 40px;
  color: #e3ebdd;
  font-weight: 100;
}

.banner-area.navigation-circle .swiper-button-prev,
.banner-area.navigation-circle .swiper-button-next {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 50%;
  border: 2px solid var(--white);
  color: var(--white);
}

.banner-area.navigation-circle .swiper-button-prev::after,
.banner-area.navigation-circle .swiper-button-next::after {
  font-size: 16px;
}

.banner-area.navigation-right-bottom .swiper-button-prev,
.banner-area.navigation-right-bottom .swiper-button-next {
  left: auto;
  right: 30px;
  opacity: 1;
  top: auto;
  -webkit-transform: inherit;
  transform: inherit;
  bottom: 50px;
  border-radius: inherit;
}

.banner-area.navigation-right-bottom .swiper-button-prev {
  right: 92px;
}

.banner-area.navigation-between-bottom .content {
  padding-bottom: 105px;
}

@media only screen and (min-width: 829px) and (max-width: 1023px) {
  .banner-area.navigation-between-bottom .content {
    padding-bottom: 220px;
  }
}

.banner-area.navigation-between-bottom .swiper-button-prev,
.banner-area.navigation-between-bottom .swiper-button-next {
  left: auto;
  right: 30px;
  opacity: 1;
  top: auto;
  -webkit-transform: inherit;
  transform: inherit;
  bottom: 30px;
  border-radius: inherit;
}

.banner-area.navigation-between-bottom .swiper-button-prev {
  left: 30px;
  right: auto;
}

@media only screen and (max-width: 830px) {
  .banner-area .swiper-button-prev,
  .banner-area .swiper-button-next {
    display: none;
  }
}

.banner-area.navigation-custom .swiper-button-prev::after {
  font-family: "ElegantIcons";
  content: "\23";
  font-size: 22px;
}

.banner-area.navigation-custom .swiper-button-next::after {
  font-family: "ElegantIcons";
  content: "\24";
  font-size: 22px;
}

.banner-area.navigation-text .swiper-button-prev,
.banner-area.navigation-text .swiper-button-next {
  opacity: 1;
  top: auto;
  -webkit-transform: inherit;
  transform: inherit;
  left: auto;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 150px;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 100px;
  line-height: 100px;
}

@media only screen and (max-width: 830px) {
  .banner-area.navigation-text .swiper-button-prev,
  .banner-area.navigation-text .swiper-button-next {
    display: none;
  }
}

.banner-area.navigation-text .swiper-button-prev {
  right: 151px;
}

.banner-area.navigation-text .swiper-button-prev::after {
  font-family: var(--font-heading);
  content: "Prev";
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
}

.banner-area.navigation-text .swiper-button-next::after {
  font-family: var(--font-heading);
  content: "Next";
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.6px;
}

.banner-area.navigation-icon-solid .swiper-button-prev::after,
.banner-area.navigation-icon-solid .swiper-button-next::after {
  font-size: 30px;
}

.banner-area.navigation-custom-large .swiper-button-prev,
.banner-area.navigation-custom-large .swiper-button-next {
  min-width: 65px;
  height: 30px;
  margin: 0;
}

.banner-area.navigation-custom-large .swiper-button-prev::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f104";
  font-weight: 100;
  font-size: 30px;
  position: absolute;
  left: 8px;
}

.banner-area.navigation-custom-large .swiper-button-prev::before {
  position: absolute;
  top: 50%;
  right: inherit;
  bottom: inherit;
  left: 12px;
  content: "";
  height: 2px;
  width: 50px;
  z-index: -1;
  background-color: var(--white);
  margin-top: -1px;
}

.banner-area.navigation-custom-large .swiper-button-next::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  font-weight: 100;
  font-size: 30px;
  position: absolute;
  right: 8px;
}

.banner-area.navigation-custom-large .swiper-button-next::before {
  position: absolute;
  top: 50%;
  right: 12px;
  bottom: inherit;
  left: inherit;
  content: "";
  height: 2px;
  width: 50px;
  z-index: -1;
  background-color: var(--white);
  margin-top: -1px;
}

.banner-area.navigation-right-botom .swiper-button-prev,
.banner-area.navigation-right-botom .swiper-button-next {
  position: absolute;
  left: auto;
  right: 30px;
  top: auto;
  bottom: 40px;
  opacity: 1;
  -webkit-transform: inherit;
  transform: inherit;
}

.banner-area.navigation-right-botom .swiper-button-prev {
  right: 60px;
}

.banner-area.navigation-right-botom.navigation-custom-large .swiper-button-prev,
.banner-area.navigation-right-botom.navigation-custom-large
  .swiper-button-next {
  bottom: 30px;
}

.banner-area.navigation-right-botom.navigation-custom-large
  .swiper-button-prev {
  right: 100px;
}

.banner-area .swiper-pagination {
  height: auto;
  bottom: 35px;
}

.banner-area .swiper-pagination span.swiper-pagination-bullet {
  height: 4px;
  width: 50px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  border-radius: inherit;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.banner-area
  .swiper-pagination
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: white;
  height: 7px;
}

@media only screen and (max-width: 767px) {
  .banner-area.include-pagination .content {
    padding-bottom: 100px;
  }
}

.banner-area .swiper-pagination-fraction span {
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.banner-area .swiper-pagination-fraction span.swiper-pagination-current {
  font-size: 30px;
  font-family: var(--font-heading);
  font-weight: 600;
}

/* ============================================================== 
    # Banner Custom Style
=================================================================== */
/* Banner Style one */

.banner-area.banner-style-one {
  height: auto;
}

.banner-area.banner-style-one div {
  height: auto;
}

.banner-area.banner-style-one .content {
  padding-top: 320px;
  padding-bottom: 210px;
}

@media (min-width: 1800px) {
  .banner-area.banner-style-one .content {
    padding-top: 310px;
    padding-bottom: 300px;
  }
}

.banner-style-one h2 {
  font-size: 80px;
  margin-bottom: 25px;
}

.banner-style-one p {
  font-size: 18px;
}

.banner-style-one h4 {
  display: inline-block;
  position: relative;
  padding-right: 15px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: var(--font-default);
}

.cloud-bg {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -18%;
}

@media (min-width: 1800px) {
  .cloud-bg {
    bottom: -20%;
  }
}

.cloud {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: auto;
  height: auto;
  z-index: 2;
  transform: translateX(-50%);
}

.cloud:nth-child(2) {
  left: -50%;
  transform: inherit;
  bottom: 10%;
}

.cloud:nth-child(3) {
  right: -20%;
  left: auto;
  transform: inherit;
  bottom: 20%;
}

.banner-area.banner-style-one .swiper-slide.swiper-slide-active h4 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}

.banner-area.banner-style-one .swiper-slide.swiper-slide-active h2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
}

.banner-area.banner-style-one .swiper-slide.swiper-slide-active p,
.banner-area.banner-style-one .swiper-slide.swiper-slide-active ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 900ms;
  transition-delay: 900ms;
}

.banner-area.banner-style-one .swiper-slide.swiper-slide-active .button {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.banner-area.banner-style-one .video-play-button {
  position: absolute;
  right: 50px;
  top: 50%;
  overflow: inherit;
  background: transparent;
  z-index: 1;
  transform: translateY(-50%);
}

.banner-area.banner-style-one .video-play-button i {
  background: var(--white);
  color: var(--color-heading);
}

.banner-area.banner-style-one i::before {
  position: relative;
}

/* Banner Two */
.banner-style-two h4 {
  font-family: var(--font-default);
  font-weight: 500;
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0 15px;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.banner-style-two h4::after {
  position: absolute;
  left: -100%;
  top: 7px;
  content: "";
  height: 15px;
  width: 100%;
  background: url(../img/shape/17.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  filter: brightness(0) invert(1);
}

.banner-style-two h4::before {
  position: absolute;
  right: -100%;
  top: 7px;
  content: "";
  height: 15px;
  width: 100%;
  background: url(../img/shape/18.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  filter: brightness(0) invert(1);
}

.banner-style-two h2 {
  font-size: 70px;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 30px;
}

.banner-style-two h2 strong {
  display: block;
}

.banner-area.banner-style-two {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-area.banner-style-two,
.banner-area.banner-style-two div {
  height: auto;
}

.banner-area.banner-style-two .content {
  padding-top: 260px;
  padding-bottom: 210px;
}

.banner-area.banner-style-two .swiper-slide.swiper-slide-active h4 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}

.banner-area.banner-style-two .swiper-slide.swiper-slide-active h2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
}

.banner-area.banner-style-two .swiper-slide.swiper-slide-active p,
.banner-area.banner-style-two .swiper-slide.swiper-slide-active ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 900ms;
  transition-delay: 900ms;
}

.banner-area.banner-style-two .swiper-slide.swiper-slide-active .button {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.banner-style-two p {
  font-size: 24px;
}

.banner-shap img {
  position: absolute;
  left: 5%;
  top: 15%;
  z-index: 1;
  height: 80px;
  transition: all 500ms ease;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.banner-shap img:nth-child(2) {
  top: auto;
  bottom: 100px;
  left: 15%;
  transition: all 500ms ease;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.banner-shap img:nth-child(3) {
  left: auto;
  right: 12%;
  top: 15%;
  transition: all 500ms ease;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.banner-shap img:nth-child(4) {
  left: auto;
  right: 5%;
  top: auto;
  bottom: 10%;
  transition: all 500ms ease;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.banner-area.banner-style-two
  .swiper-slide.swiper-slide-active
  .banner-shap
  img {
  transform: scale(1);
  opacity: 0.4;
  visibility: visible;
}

.banner-area.banner-style-two
  .swiper-slide.swiper-slide-active
  .banner-shap
  img:nth-child(2) {
  transform: scale(1);
  opacity: 0.4;
  visibility: visible;
  -webkit-transition-delay: 1100ms;
  transition-delay: 1100ms;
}

.banner-area.banner-style-two
  .swiper-slide.swiper-slide-active
  .banner-shap
  img:nth-child(3) {
  transform: scale(1);
  opacity: 0.4;
  visibility: visible;
  -webkit-transition-delay: 800ms;
  transition-delay: 800ms;
}

.banner-area.banner-style-two
  .swiper-slide.swiper-slide-active
  .banner-shap
  img:nth-child(4) {
  transform: scale(1);
  opacity: 0.4;
  visibility: visible;
  -webkit-transition-delay: 50ms;
  transition-delay: 500ms;
}

/* Banner Three */
.banner-style-three-content,
.banner-style-three-content div {
  height: 100%;
}

.banner-style-three-area {
  height: 100%;
}

.banner-style-three-content {
  padding-top: 120px;
}

.banner-style-three-content .row {
  align-items: center;
}

.banner-style-three-content .row div {
  height: auto;
}

.banner-style-three-content h2 {
  font-size: 100px;
  text-transform: capitalize;
  line-height: 1.1;
}

ul.list-quality {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-quality li {
  text-transform: uppercase;
  display: inline-block;
  font-size: 28px;
  font-weight: 600;
  margin: 0 20px;
  position: relative;
  z-index: 1;
}

ul.list-quality li::after {
  position: absolute;
  right: -25px;
  top: 50%;
  content: "";
  height: 8px;
  width: 8px;
  background: var(--white);
  border-radius: 50%;
  transform: translateY(-50%);
}

ul.list-quality li:last-child::after {
  display: none;
}

/* Banner Style Four */

.banner-style-four-area {
  overflow: hidden;
}

.banner-style-four-area,
.banner-style-four-area div {
  height: 100%;
}

.banner-style-four-area .row div {
  height: auto;
}

.banner-style-four-area .row {
  align-items: center;
}

.banner-style-four-content {
  padding-top: 100px;
}

.banner-style-four-content h2 {
  font-size: 100px;
}

.banner-style-four-content .curve-text svg {
  background: transparent;
  border: 2px solid #ffd8a3;
}

.banner-style-four-content .curve-text svg text {
  fill: var(--white);
}

.banner-style-four-content .curve-text {
  margin-top: 20px;
}

/* Banner Five */

.banner-style-five-content {
  position: relative;
  z-index: 9;
}

.banner-style-five-content .row {
  align-items: center;
}

.banner-style-five-content .row div {
  height: auto;
}

.banner-area.banner-style-five {
  padding-top: 120px;
  overflow: hidden;
}

.banner-area.banner-style-five .banner-fade {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.banner-style-five h1 {
  text-transform: uppercase;
  font-size: 200px;
  line-height: 0.8;
  letter-spacing: 5px;
}

.banner-style-five h3 {
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  background: var(--color-secondary);
  padding: 0 20px;
  margin: 0;
}

.banner-style-five .info {
  display: inline-block;
}

.banner-style-five .info p {
  padding: 0;
  font-size: 30px;
  transform: inherit;
  opacity: 1;
  visibility: visible;
  display: block;
  min-width: 220px;
  margin-right: 15px;
}

.banner-style-five .info p strong {
  font-weight: 400;
  margin-left: 60px;
  position: relative;
  z-index: 1;
}

.banner-style-five .info p strong::after {
  position: absolute;
  left: -100%;
  content: "";
  top: 50%;
  height: 2px;
  width: 40px;
  border-top: 1px solid;
  margin-left: 15px;
  margin-top: 1px;
}

.banner-style-five .text-end {
  margin-bottom: 15px;
}

.banner-style-five .bottom {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-style-five .bottom span.marker {
  height: 2px;
  border-top: 2px solid var(--color-secondary);
  position: relative;
  width: 70%;
}

/* ============================================================== 
    # Brand
=================================================================== */
.brand-style-one-carousel img {
  max-height: 100px;
  margin: auto;
}

/* ============================================================== 
    # About
=================================================================== */

.about-style-one-area {
  margin-bottom: 80px;
}

.about-thumb {
  position: absolute;
  left: 0;
  top: 120px;
  width: 50%;
  bottom: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

.about-thumb .item {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.about-style-one-info {
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  background: var(--white);
  padding: 80px;
  margin-left: -20%;
  position: relative;
  top: 80px;
}

.about-style-one-info img {
  position: absolute;
  height: 100px;
  right: -100px;
  z-index: -1;
  top: -65px;
}

/* About Style Two */
.author-info {
  align-items: center;
  display: flex;
  margin-top: 40px;
}

.author-details {
  display: flex;
  align-items: center;
}

.author-details .author-info img {
  height: 30px;
}

.author-info p {
  margin: 0;
}

.author-details .author-content img {
  height: 40px;
  margin-bottom: 5px;
}

.author-details .author-thumb img {
  height: 56px;
  margin-right: 16px;
  display: none;
}

.author-info .btn {
  margin-right: 30px;
}

.about-style-two-info h2 {
  margin-bottom: 30px;
}

.customer-review-items {
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
  margin-top: 30px;
  padding-top: 30px;
}

.customer-reveiw-avatar img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 4px solid #ebe9e6;
  margin-left: -25px;
}

.customer-reveiw-avatar {
  margin-left: 26px;
  margin-right: 20px;
}

.customer-review-info .icon {
  display: flex;
  align-items: center;
}

.customer-review-info .icon p {
  margin: 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.customer-review-info h6 {
  margin-bottom: 5px;
  font-size: 20px;
}

.customer-review-info .icon p strong {
  font-weight: 900;
  font-size: 20px;
  margin-right: 4px;
  line-height: 1;
}

.customer-review-info .icon i {
  font-size: 15px;
  color: var(--color-primary);
}

.about-style-two-thumb {
  position: relative;
}

.about-style-two-thumb {
  margin: auto;
  border-radius: 0 0 480px 485px;
  overflow: hidden;
  text-align: center;
}

.about-style-two-thumb::after {
  position: absolute;
  left: 50%;
  bottom: -15px;
  content: "";
  height: 550px;
  width: 550px;
  background: var(--color-primary);
  z-index: -1;
  transform: translateX(-50%);
  border-radius: 50%;
}

.color-style-two .about-style-two-thumb::after {
  background: var(--color-secondary);
}

.about-style-thumb-box {
  position: relative;
}

.about-style-thumb-box > img {
  position: absolute;
  right: -58px;
  bottom: 0;
  height: 250px;
}

.curve-text svg {
  fill: var(--color-heading);
  height: 170px;
  width: 170px;
  overflow: inherit;
  animation: spinner 20s infinite linear;
}

.curve-text svg {
  background: var(--white);
  border-radius: 50%;
  padding: 35px;
  position: relative;
  z-index: 1;
  font-size: 20px;
  height: 200px;
  width: 200px;
  text-transform: uppercase;
  font-weight: 800;
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
}

.curve-text svg a {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
}

.curve-text {
  display: inline-block;
  position: relative;
}

.curve-text a {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: 9;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 40px;
  -webkit-text-stroke: 1px var(--white);
  color: transparent;
}

.curve-text svg path {
  fill: none;
}

.curve-text svg text {
  fill: var(--color-heading);
  letter-spacing: 4px;
}

.curve-text .thumb {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  height: 80px;
  width: 80px;
  background: var(--dark);
  text-align: center;
  line-height: 74px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.curve-text img {
  height: 30px;
}

.about-style-thumb-box .curve-text {
  position: absolute;
  left: 0;
  bottom: 0;
}

.shape-overlay {
  position: absolute;
  left: 0;
  bottom: -120px;
  z-index: 2;
}

.shape-overlay img {
  height: 200px;
}

/* About Style Three */

.about-style-three-info .title {
  margin-bottom: 30px;
}

.item-grid-two-colum {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 50px;
}

.site-owner {
  display: flex;
  align-items: center;
  border-top: 2px solid;
  padding-top: 25px;
}

.site-owner img {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-right: 20px;
}

.site-owner h4 {
  font-size: 20px;
  margin-bottom: 5px;
}

.site-owner span {
  color: var(--color-primary);
  text-transform: uppercase;
  font-weight: 600;
}

.color-style-two .site-owner span {
  color: var(--color-paragraph);
}

.about-style-three-thumb {
  padding-right: 70px;
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
}

.about-style-three-thumb img:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 50%;
}

.about-style-three-thumb::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 40%;
  width: 30px;
  background: var(--color-primary);
  /* border-radius: 50px; */
}

/* Thumb Style Two */
.thumb-style-two {
  position: relative;
  z-index: 1;
}

.thumb-style-two img:nth-child(2) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.thumb-style-two img:first-child {
  padding: 40px;
}

ul.card-info {
  margin: 0;
  padding: 30px;
  background: var(--bg-gray);
  position: relative;
}

ul.card-info h5 {
  margin-bottom: 10px;
}

ul.card-info p {
  margin: 0;
}

ul.card-info li {
  margin-top: 30px;
}

ul.card-info li:first-child {
  margin-top: 0;
}

ul.card-info::after {
  position: absolute;
  right: -15px;
  bottom: -15px;
  content: "";
  height: 100%;
  left: 30px;
  border: 1px solid #cccccc;
  z-index: -1;
}

/* ============================================================== 
    # Top Feature
=================================================================== */
.top-feature-style-one-area {
  position: relative;
  z-index: 1;
}

.top-feature-style-one-area::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 50%;
  background: var(--color-primary);
  z-index: -1;
}

.opening-hours-hightlight ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.opening-hours-hightlight ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 10px;
}

.opening-hours-hightlight ul li:first-child {
  margin-top: 0;
  padding-top: 0;
  border: none;
}

.opening-hours-hightlight ul li span {
  padding-right: 30px;
  font-weight: 600;
}

.opening-hours-hightlight ul li .pull-right {
  padding-left: 30px;
}

.opening-hours-hightlight .title {
  margin-bottom: 40px;
}

.reservation-style-two h2 {
  margin-bottom: 30px;
}

.opening-hours-hightlight ul li .pull-right.closed {
  background: var(--white);
  color: var(--color-heading);
  text-align: center;
  padding: 0px 20px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
}

.reservation-style-two label {
  display: block;
  float: left;
  width: 100%;
  color: var(--color-heading);
  font-weight: 600;
}

.reservation-style-two form .row {
  margin-bottom: 15px;
}

.reservation-style-two form .row .nice-select {
  margin-bottom: 0;
}

.date-picker-one span.input-group-addon {
  background: var(--color-primary);
  color: var(--white);
  line-height: 50px;
  min-width: 50px;
  text-align: center;
  font-weight: 100;
  border-radius: 0 5px 5px 0;
}

.reservation-style-two input,
.reservation-style-two .nice-select {
  border-color: var(--color-primary);
  border-radius: 5px;
}

.input-group.date.date-picker-one input {
  border-radius: 5px 0 0 5px !important;
}

.shape-bottom-right {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 20%;
  opacity: 0.2;
}

.opening-hours-hightlight {
  position: relative;
  z-index: 1;
}

.reservation-form.light::after {
  background: var(--white);
}

.reservation-form.light {
  box-shadow: rgb(0 0 0 / 5%) 0px 10px 30px 0px;
  top: -50px;
  z-index: 9;
  border-radius: 30px;
  margin-bottom: -50px;
}

/* ============================================================== 
    # Feature Style two
=================================================================== */

/* ============================================================== 
    # Food Menu
=================================================================== */
.food-menu-area {
  position: relative;
  z-index: 1;
}

.food-menu-area::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: var(--black);
  z-index: -1;
  min-height: 500px;
}

.food-menu-area.shape-less::after {
  background: var(--bg-gray);
}

.food-menu-area::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: url(../img/shape/3.png);
  min-height: 500px;
}

.food-menu-area.shape-less::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: url(../img/shape/3-dark.png);
  min-height: 500px;
}

.nav.nav-tabs.food-menu-nav {
  display: inline-block;
  text-align: center;
  margin: 0;
  margin-bottom: 50px;
  border: 1px dashed rgba(255, 255, 255, 0.4);
  border-radius: 50px;
  overflow: hidden;
}

.nav.nav-tabs.food-menu-nav button {
  display: inline-block;
  border: 1px solid;
  color: var(--white);
  border-radius: 0;
  margin: 0;
  padding: 15px 30px;
  text-transform: uppercase;
  font-size: 17px;
  border: none;
}

.nav.nav-tabs.food-menu-nav button.active {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.color-style-two .nav.nav-tabs.food-menu-nav button.active {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.nav.nav-tabs.food-menu-nav button::after {
  display: none;
}

.tab-content.food-menu-tab-content {
  background: var(--dark-secondary);
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.tab-content.food-menu-tab-content .thumb {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}

.tab-content.food-menu-tab-content .info {
  padding: 65px 50px;
  padding-right: 65px;
  padding-left: 130px;
}

.shape-less .nav.nav-tabs.food-menu-nav {
  border: 1px dashed #9b7373;
  background: var(--white);
  position: relative;
  z-index: 1;
}

.shape-less .nav.nav-tabs.food-menu-nav button {
  color: var(--color-heading);
}

.shape-less .nav.nav-tabs.food-menu-nav button.active {
  color: var(--white);
}

ul.meal-type {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 15px;
}

ul.meal-type li {
  display: inline-block;
  border: 1px solid;
  margin-left: 10px;
  padding: 1px 10px;
  font-weight: 600;
  padding-top: 3px;
}

ul.meal-items li {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 5.5fr;
  grid-column-gap: 30px;
  align-items: center;
}

ul.meal-items.thumb-less li {
  display: block;
}

ul.meal-items li .thumbnail img {
  border-radius: 50%;
  margin-right: 30px;
}

ul.meal-items li .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

ul.meal-items {
  display: grid;
  grid-row-gap: 30px;
}

.food-menus-item .title,
.food-menu-items .title,
.meal-items .title {
  margin-top: 0;
}

ul.meal-items li .content .top h4 {
  margin: 0;
  background: var(--white);
  padding-right: 20px;
}

.food-menu-items ul.meal-items li .content .top h4 {
  background: var(--dark-secondary);
}

ul.meal-items li .content .bottom {
  display: grid;
  grid-template-columns: 2.2fr 1fr;
  justify-content: space-between;
  grid-column-gap: 50px;
}

ul.meal-items li .content .bottom .right {
  text-align: right;
}

.text-light ul.meal-items li .content .bottom p {
  color: #aba8a8;
}

ul.meal-items li .content .bottom p {
  margin: 0;
  text-transform: capitalize;
}

ul.meal-items li .content .top .price {
  font-size: 24px;
  font-weight: 900;
  background: var(--white);
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.color-style-two ul.meal-items li .content .top .price {
  color: var(--color-secondary);
}

.food-menu-items ul.meal-items li .content .top .price {
  background: var(--dark-secondary);
}

ul.meal-items li .content .top .price span {
  line-height: 1.2;
  margin-left: 20px;
}

ul.meal-items li .content .top::after {
  position: absolute;
  left: 0;
  top: 19px;
  content: "";
  height: 1px;
  width: 100%;
  border-top: 1px solid;
  z-index: -1;
  opacity: 0.6;
}

ul.meal-items li .content .top::before {
  position: absolute;
  left: 0;
  top: 14px;
  content: "";
  height: 1px;
  width: 100%;
  border-top: 1px solid;
  z-index: -1;
  opacity: 0.6;
}

ul.meal-items li .content .top .price span:first-child {
  margin-left: 20px;
}

.discount-badge {
  position: absolute;
  right: -98px;
  top: 80px;
  height: 200px;
  width: 200px;
  background: var(--color-primary);
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  font-family: var(--font-heading);
  line-height: 1.2;
  justify-content: center;
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
}

.color-style-two .discount-badge {
  background: var(--color-secondary);
}

.discount-badge strong {
  display: block;
  font-size: 40px;
  font-weight: 900;
  font-family: var(--font-default);
  color: #ff9e07;
}

.food-menus-item .discount-badge strong {
  font-family: var(--font-heading);
}

/* Food menu style two */

.tab-content.food-menu-tab-content.style-two {
  background: transparent;
}

.tab-content.food-menu-tab-content.style-two .info {
  padding: 0;
}

.tab-content.food-menu-tab-content.style-two ul.meal-items li .content .top h4 {
  background: var(--bg-gray);
}

.tab-content.food-menu-tab-content.style-two
  ul.meal-items
  li
  .content
  .top
  .price {
  background: var(--bg-gray);
}

.tab-content.food-menu-tab-content.style-two
  ul.meal-items
  li
  .content
  .bottom
  p {
  color: var(--color-paragraph);
}

.nav.nav-tabs.food-menu-nav.style-two {
  position: relative;
  z-index: 1;
  text-align: left;
  overflow: inherit;
}

.nav.nav-tabs.food-menu-nav.style-two::after {
  position: absolute;
  right: -70px;
  top: -100%;
  content: "";
  height: 600%;
  width: 400%;
  background: var(--dark);
  z-index: -1;
}

.nav.nav-tabs.food-menu-nav.style-two::before {
  position: absolute;
  right: -70px;
  top: -100%;
  content: "";
  height: 600%;
  width: 400%;
  background: url(../img/shape/3.png);
}

.nav.nav-tabs.food-menu-nav.style-two h2 {
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.nav.nav-tabs.food-menu-nav.style-two button {
  margin: 0;
  width: 100%;
  margin-top: 15px;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: var(--dark);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav.nav-tabs.food-menu-nav.style-two button.active {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.color-style-two .nav.nav-tabs.food-menu-nav.style-two button.active {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

/* ============================================================== 
    # Food Menu All
=================================================================== */
.food-menus-item {
  overflow: hidden;
  margin-top: 120px;
}

.food-menus-item:first-child {
  margin-top: 0;
}

.food-menus-item .thumb {
  background-size: cover !important;
  background-position: center !important;
  position: relative;
}

.food-menus-item .info {
  padding: 65px 50px;
  padding-right: 0;
  padding-left: 130px;
}

.food-menus-item ul.meal-items li .content .top h4 {
  background: var(--white);
}

.food-menus-item ul.meal-items li .content .top .price {
  background: var(--white);
}

.food-menus-item ul.meal-items li .content .bottom p {
  color: var(--color-paragraph);
}

.food-menus-item:nth-child(2n) .info {
  padding-left: 0;
  padding-right: 140px;
}

.food-menus-item:nth-child(2n) .discount-badge {
  right: auto;
  left: -98px;
}

/* Food Menu Style Three */
.nav.nav-tabs.food-menu-nav.style-three {
  border-radius: 0;
  border: 1px solid;
  overflow: inherit;
  padding: 10px;
  border-radius: 5px;
}

.nav.nav-tabs.food-menu-nav.style-three button {
  color: var(--color-heading);
  position: relative;
  z-index: 1;
  overflow: inherit;
}

.nav.nav-tabs.food-menu-nav.style-three button.active {
  background: transparent;
  color: var(--white);
}

.nav.nav-tabs.food-menu-nav.style-three button::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 100%;
  background: var(--color-primary);
  transition: all 0.35s ease-in-out;
  width: 0;
  z-index: -1;
  border-radius: 5px;
}

.nav.nav-tabs.food-menu-nav.style-three button.active::before {
  width: 100%;
}

.food-menu-style-three .thumb {
  position: relative;
}

.food-menu-style-three .thumb .d-flex {
  position: absolute;
  left: 0;
  bottom: 0;
  border-left: 1px solid #dddddd;
  border-top-right-radius: 10px;
  padding: 30px;
  background: var(--white);
  font-weight: 800;
  align-items: center;
  gap: 30px;
  padding-bottom: 0;
}

.food-menu-style-three .info {
  border: 1px solid #dddddd;
  padding: 30px;
  border-top: none;
  padding-top: 20px;
  border-radius: 0 0 20px 20px;
}

.food-menu-style-three {
  overflow: hidden;
  border-radius: 10px;
}

.food-menu-style-three .thumb .d-flex::after {
  position: absolute;
  right: -15px;
  bottom: 0;
  content: "";
  height: 15px;
  width: 15px;
  background: url('data:image/svg+xml,<svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-11 h-11"><path d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z" fill="%23ffffff"></path></svg>');
  transform: rotate(-90deg);
}

.food-review i {
  color: #f7a105;
}

.food-menu-style-three .thumb .d-flex .price {
  font-weight: 900;
  font-family: var(--font-default);
  margin-bottom: 0;
}

.food-menu-style-three .thumb .d-flex .price span {
  display: flex;
  align-items: center;
  line-height: 1;
}

.food-menu-style-three .thumb .d-flex .price del {
  margin-right: 5px;
  font-size: 17px;
}

.cart-btn {
  display: inline-flex;
  border: 2px solid var(--color-primary);
  padding: 12px 25px;
  border-radius: 30px;
  text-transform: uppercase;
  align-items: center;
  font-size: 13px;
}

.cart-btn i {
  display: inline;
  font-weight: 100;
  font-size: 20px;
  margin-right: 10px;
}

.cart-btn:hover {
  background: var(--color-primary);
  color: var(--white);
}

.food-style-two-content {
  margin-top: -30px;
}

.food-menu-style-three ul li {
  list-style: disc;
  display: inline-block;
  text-transform: capitalize;
  font-weight: 600;
  position: relative;
  z-index: 1;
  padding-left: 15px;
  margin-left: 7px;
}

.food-menu-style-three ul {
  margin: 0;
  padding: 0;
}

.food-menu-style-three ul li::after {
  position: absolute;
  left: 0;
  top: 50%;
  height: 6px;
  width: 6px;
  transform: translateY(-50%);
  content: "";
  background: #826a45;
  border-radius: 50%;
  z-index: -1;
  margin-top: 2px;
}

.food-menu-style-three ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.food-menu-style-three ul li:first-child::after {
  display: none;
}

.cart-btn-border {
  display: block;
  border: 1px solid var(--color-primary);
  padding: 10px 30px;
  text-align: center;
  border-radius: 30px;
  margin-top: 25px;
}

.cart-btn-border i {
  font-weight: 100;
  margin-right: 5px;
}

.cart-btn-border:hover {
  background: var(--color-primary);
  color: var(--white);
}

/* ============================================================== 
    # Food Menu Style Four 
=================================================================== */

.nav.nav-tabs.food-menu-nav.four .nav-link {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav.nav-tabs.food-menu-nav.four .nav-link img {
  height: 60px;
  margin-bottom: 15px;
}

.color-style-two .nav.nav-tabs.food-menu-nav.style-three button::before {
  background: transparent;
  width: 2px;
  border-right: 1px dashed #cccccc;
}

.color-style-two
  .nav.nav-tabs.food-menu-nav.style-three
  button:first-child::before {
  display: none;
}

.nav.nav-tabs.food-menu-nav.style-three.four button.active {
  color: var(--color-secondary);
}

.nav.nav-tabs.food-menu-nav.style-three.four {
  padding: 0;
  border: none;
}

.nav.nav-tabs.food-menu-nav.style-three.four button {
  padding: 0 45px;
}

.food-style-four-content .food-menus-item ul.meal-items li .content .top h4 {
  font-size: 20px;
}

.bg-dark
  .food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top
  h4 {
  background: #363434;
}

.food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top
  .price {
  font-size: 20px;
  color: var(--color-secondary);
}

.food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top::after {
  top: 18px;
  border-color: #cccccc;
}

.food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top::before {
  display: none;
}

.tab-content.food-style-four-content {
  background: var(--white);
  padding: 80px;
}

.nav.nav-tabs.food-menu-nav.style-three.four button {
  position: relative;
  z-index: 1;
}

.nav.nav-tabs.food-menu-nav.style-three.four button::after {
  position: absolute;
  left: 50%;
  bottom: -50px;
  content: "";
  height: 20px;
  width: 35px;
  background: var(--white);
  display: block;
  right: auto;
  transform: translateX(-50%);
  z-index: -1;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transition: all 0.35s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.nav.nav-tabs.food-menu-nav.style-three.four button.active::after {
  visibility: visible;
  opacity: 1;
}

.food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top
  .price
  span {
  background: rgba(255, 6, 0, 0.1);
  padding: 5px 10px;
  font-weight: 700;
  border-radius: 5px;
}

.food-menu-style-four-items {
  position: relative;
  z-index: 1;
}

.food-menu-style-four-items .animate-up-down {
  position: absolute;
  right: -150px;
  bottom: 100px;
  max-width: 200px;
}

@media (min-width: 1024px) {
  .food-style-four-content .row {
    --bs-gutter-x: 80px;
  }
}

@media (max-width: 1199px) {
  .food-style-four-content .row {
    gap: 50px;
  }
}

@media (max-width: 1400px) {
  ul.meal-items li .content .top h4 {
    font-size: 20px;
  }

  ul.meal-items li .content .top .price {
    font-size: 20px;
  }

  ul.meal-items li .content .top::after {
    top: 15px;
  }

  ul.meal-items li .content .top::before {
    top: 10px;
  }

  ul.meal-items li .content .bottom {
    grid-template-columns: 2fr 1fr;
  }
}

/* ============================================================== 
    # Home Shop 
=================================================================== */
.product-style-two .product .product-contents {
  border: none;
  padding: 0;
  background: var(--white);
  overflow: hidden;
  border-radius: 10px;
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
}

.product-style-two .product .product-contents .product-caption {
  padding: 30px;
}

.food-menu-carousel.swiper .swiper-wrapper {
  padding: 15px;
  padding-bottom: 30px;
}

.food-menu-carousel {
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 30px;
}

.product-style-two .product-image {
  position: relative;
}

.product-image .product-review {
  position: absolute;
  left: 30px;
  bottom: 30px;
  background: var(--white);
  padding: 0px 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  color: var(--color-heading);
  font-weight: 600;
}

.product-image .product-review i {
  color: #fe9f10;
  font-size: 14px;
  margin-right: 5px;
}

.product-style-two .product-caption h4 {
  margin-bottom: 10px;
}

.product-style-two .product-caption .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}

.product-style-two .product-caption .bottom .price {
  font-weight: 800;
  margin: 0;
}

.product-style-two .product-caption .bottom a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 38px;
  border: 1px solid var(--color-primary);
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  color: var(--white);
  background: var(--color-primary);
}

.product-style-two .product-caption .bottom a i {
  font-weight: 100;
}

.product-style-two .product-caption p {
  margin-bottom: 15px;
}

.product-style-two .product-image .shop-action ul {
  right: 20px;
  top: 30px;
}

.product-style-two .shop-action li a::after {
  background: var(--white);
  color: var(--color-heading);
}

/* ============================================================== 
    # Services 
=================================================================== */

.services-style-one-area {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.services-style-one .info {
  padding: 45px;
}

.services-style-one {
  position: relative;
  background: var(--white);
  border-radius: 30px;
}

.services-style-one-carousel {
  position: relative;
}

.services-style-one img {
  border-radius: 30px 30px 0 0;
  width: 100%;
}

.services-style-one ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}

.services-style-one ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-top: 10px;
  font-weight: 600;
  text-transform: capitalize;
}

.services-style-one ul li:first-child {
  margin-top: 0;
}

.services-style-one ul li::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  height: 2px;
  width: 100%;
  border-top: 1px solid #cccccc;
  z-index: -1;
}

.services-style-one ul li strong {
  background: var(--white);
  padding-left: 20px;
}

.services-style-one ul li span {
  background: var(--white);
  padding-right: 20px;
  min-width: 130px;
  text-align: left;
}

.services-style-one ul li i {
  font-size: 13px;
  position: relative;
  top: -2px;
  color: #f7a105;
}

.services-style-one h4 {
  font-family: var(--font-optional);
  font-weight: 900;
  font-size: 30px;
}

.services-style-one .thumb {
  position: relative;
  z-index: 1;
}

.services-style-one .thumb h4 {
  position: absolute;
  left: 0;
  bottom: -10px;
  background: var(--color-secondary);
  margin: 0;
  color: var(--white);
}

.services-style-one .thumb h4 a {
  color: var(--white);
  padding: 10px 30px;
  display: inline-block;
}

.services-style-one .thumb h4::after {
  position: absolute;
  right: -29px;
  top: -1px;
  content: "";
  height: 84%;
  width: 30px;
  background: #f56166;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.services-style-one .btn {
  border: 1px solid #cccccc;
  padding: 14px 45px;
}

.services-style-one-items {
  position: relative;
  z-index: 1;
}

.shape-right-top {
  position: absolute;
  right: 0;
  top: -120px;
  text-align: right;
  z-index: -1;
}

.shape-right-top img {
  max-width: 50%;
}

.services-swiper-nav {
  position: absolute;
  right: 0;
  top: -100px;
  display: flex;
  width: 100px;
  justify-content: space-between;
  align-items: center;
}

.services-swiper-nav > div {
  height: 40px;
  width: 40px;
  background: var(--color-primary);
  z-index: 9;
  position: relative;
  border-radius: 50%;
}

.color-style-two .services-swiper-nav > div {
  background: var(--color-secondary);
}

.services-swiper-nav > div::after {
  position: absolute;
  top: 7px;
  content: "";
  height: 100%;
  width: 100%;
  font-family: "Font Awesome 5 Pro";
  text-align: center;
  color: var(--white);
}

.services-swiper-nav .services-cat-next::after {
  content: "\f105";
}

.services-swiper-nav .services-cat-prev::after {
  content: "\f104";
}

/* ============================================================== 
    # Download App
=================================================================== */
.download-app-items {
  padding: 120px 50px;
  border-radius: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.download-app-items .d-flex {
  gap: 15px;
  margin-top: 40px;
}

.download-app-items h2 {
  margin-bottom: 35px;
}

/* ============================================================== 
    # Deliver Process
=================================================================== */
.deliverya-process-area {
  background-position: bottom right !important;
}

.deliverya-process-area .shape {
  position: absolute;
  right: 5%;
  text-align: right;
  z-index: 1;
  width: 30%;
  bottom: -50px;
}

@media (max-width: 1400px) {
  .deliverya-process-area .shape {
    display: none;
  }
}

.deliverya-process-area .shape img {
  height: 550px;
  width: auto;
  max-width: inherit;
}

.delivary-projcess h2 {
  font-size: 70px;
  margin-bottom: 30px;
  font-weight: 800;
  text-transform: uppercase;
}

/* ============================================================== 
    # Gallery
=================================================================== */
.gallery-style-one {
  overflow: hidden;
}

.gallery-style-one a {
  display: block;
  position: relative;
  z-index: 1;
}

.gallery-style-one img {
  max-width: 100%;
  position: relative;
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.35s ease-in-out;
}

.gallery-style-one:hover img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.gallery-style-one .overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  padding-left: 60px;
  padding-bottom: 60px;
  z-index: 1;
  transition: all 0.35s ease-in-out;
}

.gallery-style-one .overlay h4 {
  margin: 0;
  transition: all 0.35s ease-in-out;
  transform: translateX(10px);
  opacity: 0;
  visibility: hidden;
}

.gallery-style-one .overlay span {
  color: #c28b36;
  text-transform: uppercase;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  transition: all 0.35s ease-in-out;
  transform: translateX(15px);
  opacity: 0;
  visibility: hidden;
}

.color-style-two .gallery-style-one .overlay span {
  color: var(--white);
}

.gallery-style-one .overlay span {
  color: var(--white);
  opacity: 0.8;
}

.gallery-style-one .overlay h4 {
  color: var(--white);
  font-weight: 700;
}

.gallery-style-one .overlay h4 a {
  color: var(--white);
}

.gallery-style-one:hover .overlay h4 {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
  transition-delay: 0.3s;
}

.gallery-style-one:hover .overlay span {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.gallery-style-one::after {
  position: absolute;
  left: 45px;
  bottom: 30px;
  content: "";
  height: 0;
  width: 2px;
  border-left: 2px solid #c28b36;
  transition: all 0.35s ease-in-out;
  z-index: 1;
}

.swiper .gallery-style-one::after {
  left: 30px;
  bottom: 15px;
}

.color-style-two .gallery-style-one::after {
  border-left: 2px solid var(--color-secondary);
}

.gallery-style-one:hover::after {
  height: 50%;
}

.gallery-style-one a::before {
  position: absolute;
  left: 15px;
  bottom: 30px;
  content: "";
  width: 0;
  height: 2px;
  border-bottom: 2px solid #c28b36;
  z-index: 1;
  transition: all 0.35s ease-in-out;
}

.swiper-slide .gallery-style-one .item::before {
  position: absolute;
  left: 15px;
  bottom: 30px;
  content: "";
  width: 0;
  height: 2px;
  border-bottom: 2px solid #c28b36;
  z-index: 1;
  transition: all 0.35s ease-in-out;
}

.swiper-slide .gallery-style-one:hover .item::before {
  width: 50%;
}

.swiper-slide .gallery-style-one .item a::before,
.swiper-slide .gallery-style-one .item a::after {
  display: none;
}

.color-style-two .gallery-style-one a::before {
  border-bottom: 2px solid var(--color-secondary);
}

.color-style-two .swiper-slide .gallery-style-one .item::before {
  border-bottom: 2px solid var(--color-secondary);
}

.gallery-style-one a:hover::before {
  width: 50%;
}

.color-style-two .gallery-style-one .item:hover::before {
  border-bottom: 2px solid var(--color-secondary);
}

.gallery-style-one .item a::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--black);
  opacity: 0;
  transition: all 0.35s ease-in-out;
  visibility: hidden;
}

.swiper-slide .gallery-style-one .item {
  position: relative;
  z-index: 1;
}

.swiper-slide .gallery-style-one .item::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--black);
  opacity: 0;
  transition: all 0.35s ease-in-out;
  visibility: hidden;
}

.gallery-style-one:hover .item a::after {
  opacity: 0.7;
  visibility: visible;
}

.swiper-slide .gallery-style-one:hover .item::after {
  opacity: 0.7;
  visibility: visible;
}

.gallery-style-one-carousel.swiper .swiper-wrapper {
  padding-bottom: 60px;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  margin-bottom: -10px;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  opacity: 1;
  visibility: visible;
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet {
  position: relative;
  z-index: 1;
  background: var(--color-primary);
  margin: 0 8px;
  opacity: 1;
}

.color-style-two
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet {
  background: var(--color-secondary);
}

.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet::after {
  position: absolute;
  left: -6px;
  top: -6px;
  content: "";
  height: 20px;
  width: 20px;
  border: 1px solid;
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.color-style-two
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet::after {
  border: 1px solid var(--color-heading);
}

button.mfp-arrow::after {
  display: none;
}

.mfp-arrow-left:before {
  border-right: 27px solid var(--white);
  opacity: 1;
  z-index: 1;
}

.mfp-arrow-right:before {
  border-left: 27px solid var(--white);
  z-index: 1;
  opacity: 1;
}

/* Gallery Style Two */

.gallery-items.gallery-style-two {
  margin: -15px;
  margin-top: 0;
}

.gallery-items.gallery-style-two.colums-3 .gallery-item {
  float: left;
  padding: 15px;
  width: 33.3333%;
}

/* Tablet Layout: 768px.*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallery-items.gallery-style-two.colums-3 .gallery-item {
    width: 50%;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .gallery-items.gallery-style-two.colums-3 .gallery-item {
    width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .gallery-items.gallery-style-two {
    margin: 0;
  }

  .gallery-items.gallery-style-two.colums-3 .gallery-item {
    width: 100%;
    padding: 15px 0;
  }
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  background: transparent;
  opacity: 1;
  font-weight: 100;
}

button.mfp-prevent-close {
  background: transparent;
}

/* ============================================================== 
    # Chef
=================================================================== */

.chef-area.default-padding {
  padding-bottom: 70px;
}

.chef-thumb {
  height: 280px;
  width: 280px;
  margin: auto;
  position: relative;
  z-index: 1;
}

.chef-thumb img {
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
}

.chef-thumb::after {
  position: absolute;
  left: -20px;
  top: -20px;
  height: 320px;
  width: 320px;
  content: "";
  border: 2px solid;
  border-radius: 50%;
  z-index: -1;
}

.chef-thumb .info {
  position: absolute;
  left: -15px;
  bottom: 0;
  background: var(--color-primary);
  right: -15px;
  padding: 15px;
}

.color-style-two .chef-thumb .info {
  background: var(--color-secondary);
}

.chef-thumb .info span {
  color: var(--white);
  display: block;
  text-transform: uppercase;
}

.chef-thumb .info h4 a {
  text-transform: capitalize;
  color: var(--white);
  font-weight: 400;
}

.chef-thumb .info h4 {
  margin-bottom: 4px;
}

.chef-thumb .info::after {
  position: absolute;
  right: -20px;
  top: 0;
  content: "";
  height: 100%;
  width: 30px;
  background: var(--color-primary);
  clip-path: polygon(0% 0%, 100% 0%, 44% 50%, 100% 100%, 0% 100%);
}

.color-style-two .chef-thumb .info::after {
  background: var(--color-secondary);
}

.chef-thumb .info::before {
  position: absolute;
  left: -20px;
  top: 0;
  content: "";
  height: 100%;
  width: 30px;
  background: var(--color-primary);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 56% 48%);
}

.color-style-two .chef-thumb .info::before {
  background: var(--color-primary);
}

.chef-thumb ul.social {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.chef-thumb ul.social li {
  display: inline-block;
  margin: 0 3px;
}

.chef-thumb ul.social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: var(--white);
  color: var(--color-heading);
  border-radius: 50%;
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
}

.chef-style-one:hover .chef-thumb ul.social li a {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.chef-thumb ul.social li:nth-child(2) a {
  transition-duration: 0.6s;
}

.chef-thumb::before {
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--dark);
  content: "";
  border-radius: 50%;
  transition: all 0.35s ease-in-out;
  opacity: 0.7;
  transform: scale(0);
}

.chef-style-one:hover .chef-thumb::before {
  transform: scale(1);
}

.chef-style-one {
  margin-top: 20px;
  margin-bottom: 80px;
}

/* ============================================================== 
     # Chef Single  
=================================================================== */

.chef-single-area .chef-content-top {
  bottom: -50px;
  margin-top: -50px;
  position: relative;
  z-index: 1;
}

.chef-single-area .chef-content-top .right-info {
  margin-bottom: 80px;
}

.chef-single-area .chef-content-top .right-info h2 {
  font-weight: 600;
}

.chef-single-area .chef-content-top .right-info p {
  padding-right: 20%;
}

.chef-single-area .chef-content-top .right-info span {
  display: block;
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 600;
  margin-bottom: 25px;
}

.chef-single-area .chef-content-top .right-info ul {
  padding: 0;
  list-style: none;
  margin: 35px 0;
}

.chef-single-area .right-info ul li {
  margin-top: 10px;
  color: var(--color-heading);
  display: flex;
  align-items: center;
}

.chef-single-area .right-info ul li p {
  margin: 0;
}

.chef-single-area .right-info > ul li i {
  min-width: 35px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  background: var(--white);
  border-radius: 5px;
  margin-right: 15px;
}

.chef-single-area .right-info ul li strong {
  font-weight: 600;
}

.chef-single-area .right-info ul li a {
  font-weight: 400;
}

.chef-single-area .right-info ul li a:hover {
  color: var(--color-primary);
}

.chef-single-area .right-info .social {
  display: flex;
  margin-top: 25px;
  font-weight: 600;
  align-items: center;
}

.chef-single-area .right-info .social h4 {
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 25px;
}

.chef-single-area .right-info .social ul {
  margin: 0;
  padding: 0;
  border: none;
}

.chef-single-area .right-info .social .share-link {
  position: relative;
  z-index: 1;
  margin-left: 15px;
  padding-right: 20px;
}

.chef-single-area .right-info .social .share-link > i {
  display: inline-block;
  height: 45px;
  background: var(--dark);
  line-height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: var(--white);
}

.chef-single-area .right-info .social ul {
  display: flex;
  list-style-type: none;
  grid-gap: 10px;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out 0s;
}

.chef-single-area .right-info .social .share-link:hover ul {
  left: 58px;
  opacity: 1;
  pointer-events: auto;
}

.chef-single-area .right-info .social ul li {
  display: inline-block;
  margin: 0;
}

.chef-single-area .right-info .social ul li a {
  display: inline-block;
  height: 45px;
  width: 45px;
  line-height: 47px;
  background: var(--white);
  text-align: center;
  border-radius: 50% !important;
}

.chef-single-area .bottom-info h2 {
  font-weight: 600;
  margin-bottom: 25px;
}

.chef-single-area .bottom-info p:last-child {
  margin-bottom: 0;
}

.chef-single-area .bottom-info .skill-items {
  padding-left: 35px;
}

.skill-items .progress-box {
  margin-bottom: 35px;
}

.chef-single-area .chef-content-top img {
  border-radius: 30px;
}

.skill-items .progress-box:last-child {
  margin-bottom: 0;
}

.skill-items .progress-box h5 {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
  position: relative;
  z-index: 1;
  font-size: 16px;
}

.skill-items .progress-box h5 span {
  position: absolute;
  font-size: 50px;
  line-height: 1;
  top: -21px;
  left: 0;
  z-index: -1;
  opacity: 0.05;
  font-weight: 600;
}

.skill-items .skill-items {
  margin-top: 40px;
}

.skill-items .progress-box .progress {
  background: transparent;
  border-bottom: none;
  box-shadow: inherit;
  border-radius: inherit;
  overflow: inherit;
}

.skill-items .progress-box .progress .progress-bar {
  height: 6px;
  border-radius: 30px;
  background: var(--color-primary);
  top: 12px;
  position: relative;
  overflow: inherit;
}

.skill-items .progress-box .progress .progress-bar span {
  position: absolute;
  right: 0;
  top: -37px;
  display: block;
  font-size: 14px;
  color: var(--white);
  font-weight: 700;
  background: var(--dark);
  padding: 0 7px;
  border-radius: 5px;
}

.skill-items .progress-box .progress .progress-bar span::after {
  position: absolute;
  left: 50%;
  bottom: -6px;
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--dark);
  transform: translateX(-50%);
}

.chef-single-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

.chef-list-item h4 {
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 28px;
}

.chef-list-item h5 {
  font-weight: 600;
  margin-bottom: 8px;
}

.chef-list-item span {
  text-transform: none;
  margin-bottom: 5px;
  color: var(--color-heading);
  display: block;
}

.chef-list-item li {
  margin-top: 30px;
}

.chef-list-item li:first-child {
  margin-top: 0;
}

.chef-single-list ul {
  border: none;
  margin-top: 0;
  padding: 0;
}

.skill-items h3 {
  font-weight: 600;
  margin-bottom: 30px;
}

.chef-list-item ul {
  border-left: 1px solid;
  padding-left: 25px;
}

.chef-list-item ul li {
  position: relative;
  z-index: 1;
}

.chef-list-item ul li::after {
  position: absolute;
  left: -33px;
  top: 3px;
  content: "";
  height: 15px;
  width: 15px;
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  background: var(--white);
}

/* ============================================================== 
    # Why Choose us
=================================================================== */

.choose-us-style-one-items {
  overflow: hidden;
}

.choose-us-style-one:nth-child(2)::after {
  position: absolute;
  left: -1px;
  bottom: 0;
  content: "";
  height: 50%;
  width: 100%;
  border-left: 1px solid var(--color-primary);
  border-bottom-left-radius: 30px;
  z-index: -1;
}

.choose-us-style-one {
  position: relative;
  z-index: 1;
  padding: 50px;
}

.choose-us-style-one:first-child::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  left: 0;
  border-top: 1px solid var(--color-primary);
  border-top-right-radius: 30px;
  z-index: -1;
}

.choose-us-style-one:first-child::before {
  position: absolute;
  right: 0;
  top: 0;
  height: 50%;
  width: 100%;
  content: "";
  border-right: 1px solid var(--color-primary);
  border-top-right-radius: 30px;
  z-index: -1;
}

.choose-us-style-one:nth-child(2)::before {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50%;
  width: 100%;
  content: "";
  border-right: 1px solid var(--color-primary);
  border-bottom-right-radius: 30px;
  z-index: -1;
}

.choose-us-style-one:nth-child(3)::after {
  position: absolute;
  left: 0;
  z-index: -1;
}

.choose-us-style-one:nth-child(2) {
  border-bottom: 1px solid var(--color-primary);
  border-radius: 30px;
}

.choose-us-style-one:nth-child(3) {
  border-top: 1px solid var(--color-primary);
  border-top-left-radius: 30px;
}

.choose-us-style-one:nth-child(3)::after {
  position: absolute;
  top: 0;
  left: -1px;
  height: 50%;
  width: 100%;
  content: "";
  border-left: 1px solid var(--color-primary);
  border-top-left-radius: 30px;
  z-index: -1;
}

.choose-us-style-one img {
  height: 100px;
  margin-bottom: 30px;
}

.choose-us-style-one p {
  margin-bottom: 0;
}

/* ============================================================== 
    # Choose Us Style Two
=================================================================== */
.feature-style-two-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.feature-style-two {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 50px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.4);
}

.feature-style-two img {
  height: 80px;
  margin-bottom: 30px;
}

.feature-style-two p {
  margin: 0;
}

.fun-fact-list .fun-fact .counter {
  justify-content: left;
  color: #ff4248;
  font-weight: 900;
  margin: 0;
  font-family: var(--font-heading);
}

.fun-fact-list .fun-fact {
  float: left;
  margin-right: 60px;
}

.fun-fact-list .fun-fact:last-child {
  margin-right: 0;
}

.fun-fact-list {
  overflow: hidden;
  margin-top: 30px;
}

.fun-fact-list .fun-fact span.medium {
  font-size: 16px;
  font-weight: 500;
}

/* ============================================================== 
    # Deal Area
=================================================================== */
.deal-style-one-items {
  padding: 120px;
  background-size: cover;
  background-position: bottom center;
  border-radius: 30px;
}

.deal-thumb {
  position: relative;
  margin: -50px;
}

.deal-thumb img {
  max-width: 50%;
  padding: 50px;
}

.deal-thumb img:nth-child(2) {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 60%;
  padding: 10px;
}

.deal-thumb img:first-child {
  position: relative;
  top: 20px;
  margin-top: -20px;
  padding: 30px;
}

.call-action {
  display: flex;
  align-items: center;
}

.call-action i {
  display: inline-block;
  height: 55px;
  width: 55px;
  line-height: 55px;
  text-align: center;
  background: var(--white);
  color: var(--color-primary);
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  top: 3px;
}

.call-action span {
  color: var(--color-heading);
  font-weight: 600;
}

.call-action h5 {
  font-family: var(--font-default);
}

.call-action h4 {
  font-family: var(--font-default);
  margin: 0;
}

.call-action a {
  font-weight: 800;
  font-size: 23px;
  color: var(--color-heading);
}

.deal-info .bottom {
  display: flex;
  align-items: center;
}

.deal-info .bottom .btn {
  margin-right: 30px;
}

.deal-info p {
  color: var(--color-heading);
}

.deal-info .sub-heading {
  color: var(--color-heading);
  margin-bottom: 20px;
}

.deal-info .sub-heading::before {
  filter: brightness(0.5);
}

.deal-info .sub-heading {
  color: var(--color-heading);
  margin-bottom: 20px;
}

.deal-info .sub-heading::before {
  filter: brightness(0.5);
}

.deal-info h2 {
  font-size: 55px;
  line-height: 1.1;
  margin-bottom: 20px;
  color: var(--color-heading) !important;
}

.bg-dark .deal-info p {
  color: var(--color-heading);
}

.deal-thumb .offer-badge {
  position: absolute;
  left: 50%;
  top: 25px;
  height: 150px;
  width: 150px;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 25px;
  line-height: 1.2;
  color: var(--color-heading);
  border: 2px dashed #927a28;
  transform: rotate(-25deg);
}

.deal-thumb .offer-badge strong {
  display: block;
  font-size: 40px;
}

/* ============================================================== 
    # Opening Hours
=================================================================== */
.opening-hours-info {
  background: var(--white);
  padding: 80px;
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  margin-left: -5%;
  position: relative;
  z-index: 1;
}

.opening-hours-thumb {
  position: relative;
}

.opening-hours-info h3 {
  font-weight: 700;
}

ul.opening-hours-table {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.opening-hours-table li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 8px;
  color: var(--color-heading);
  position: relative;
  z-index: 1;
}

ul.opening-hours-table li span {
  background: var(--white);
  padding-left: 30px;
}

ul.opening-hours-table h6 {
  margin-bottom: 0;
  background: var(--white);
  padding-right: 30px;
}

ul.opening-hours-table li::after {
  position: absolute;
  left: 0;
  top: 17px;
  content: "";
  height: 1px;
  width: 100%;
  background: #cccccc;
  z-index: -1;
}

.call-to-action {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.call-to-action .icon {
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: var(--color-primary);
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;
}

.color-style-two .call-to-action .icon {
  background: var(--color-secondary);
}

.call-to-action p {
  margin-bottom: 5px;
}

.call-to-action h4 {
  font-size: 20px;
}

.call-to-action .icon img {
  height: 40px;
}

.opening-hour-items {
  position: relative;
}

.opening-hour-items .shape {
  position: absolute;
  right: -10%;
  bottom: -180px;
  opacity: 0.2;
}

.opening-hour-items .shape img {
  height: 320px;
}

.opening-hours-thumb .mbYTP_wrapper {
  z-index: 1 !important;
  height: 100%;
  max-width: 120% !important;
}

.opening-hours-thumb.video-bg-live {
  margin-right: -20%;
  margin-top: 120px;
}

.opening-hour-items .text-fixed {
  margin: 0;
  position: absolute;
  left: 0;
  top: -15px;
  font-size: 160px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1;
  opacity: 0.04;
}

.opening-hours-info .reservation-form {
  padding: 0;
}

.opening-hours-info .reservation-form::after {
  display: none;
}

.opening-hours-info .reservation-form input,
.opening-hours-info .reservation-form input:focus {
  border: 1px solid #dddddd;
  border-radius: 5px;
  color: var(--color-heading);
}

.opening-hours-info .reservation-form .form-group .nice-select .current {
  color: var(--color-heading);
}

.opening-hours-info .reservation-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color-heading);
}

.opening-hours-info .reservation-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color-heading);
}

.opening-hours-info .reservation-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color-heading);
}

.opening-hours-info .reservation-form input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color-heading);
}

.opening-hours-info .reservation-form .form-group .nice-select {
  border: 1px solid #dddddd;
}

.opening-hours-info .reservation-form label {
  color: var(--color-heading);
}

.opening-hours-info .reservation-form button::after {
  background: var(--dark);
}

.opening-hours-info .reservation-form button:hover {
  color: var(--white);
}

/* ============================================================== 
    # Testimonials
=================================================================== */
.testimonial-thumb img {
  border-radius: 50%;
  height: 100px;
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.6);
}

.testimonial-thumb img:nth-child(2) {
  position: absolute;
  right: 5%;
  top: 10%;
  border: 2px solid var(--white);
}

.testimonial-thumb img:first-child {
  height: auto;
  clip-path: polygon(
    45% 1.33975%,
    46.5798% 0.60307%,
    48.26352% 0.15192%,
    50% 0%,
    51.73648% 0.15192%,
    53.4202% 0.60307%,
    55% 1.33975%,
    89.64102% 21.33975%,
    91.06889% 22.33956%,
    92.30146% 23.57212%,
    93.30127% 25%,
    94.03794% 26.5798%,
    94.48909% 28.26352%,
    94.64102% 30%,
    94.64102% 70%,
    94.48909% 71.73648%,
    94.03794% 73.4202%,
    93.30127% 75%,
    92.30146% 76.42788%,
    91.06889% 77.66044%,
    89.64102% 78.66025%,
    55% 98.66025%,
    53.4202% 99.39693%,
    51.73648% 99.84808%,
    50% 100%,
    48.26352% 99.84808%,
    46.5798% 99.39693%,
    45% 98.66025%,
    10.35898% 78.66025%,
    8.93111% 77.66044%,
    7.69854% 76.42788%,
    6.69873% 75%,
    5.96206% 73.4202%,
    5.51091% 71.73648%,
    5.35898% 70%,
    5.35898% 30%,
    5.51091% 28.26352%,
    5.96206% 26.5798%,
    6.69873% 25%,
    7.69854% 23.57212%,
    8.93111% 22.33956%,
    10.35898% 21.33975%
  );
}

.testimonial-thumb img:nth-child(3) {
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  border: 5px solid var(--white);
  height: 130px;
}

.testimonial-thumb img:nth-child(4) {
  position: absolute;
  right: 5%;
  bottom: 10%;
  border: 2px solid var(--white);
}

.testimonial-thumb {
  position: relative;
}

.testimonial-style-one {
  overflow: hidden;
}

.testimonial-style-one .rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 700;
  justify-content: left;
}

.testimonial-style-one .rating span {
  font-size: 20px;
  margin-left: 20px;
}

.testimonial-style-one .rating i {
  color: #f7a105;
}

.testimonial-style-one h2 {
  font-weight: 700;
  margin-bottom: 25px;
}

.testimonial-style-one p {
  font-size: 25px;
}

.testimonial-style-one .provider {
  border-top: 2px solid;
  padding-top: 30px;
}

.testimonial-carousel.swiper {
  position: relative;
  z-index: 1;
}

.testimonial-carousel.swiper
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  margin: 0;
  width: auto;
  left: auto;
  right: 0;
  bottom: 40px;
}

.testimonial-style-one .provider h4 {
  margin-bottom: 10px;
}

.testimonial-style-one .provider span {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-primary);
}

.color-style-two .testimonial-style-one .provider span {
  color: #af7b2c;
}

.color-style-two .testimonial-style-one .provider span {
  color: var(--color-secondary);
}

.testimonial-shape img {
  width: 10%;
  position: absolute;
  top: 120px;
  opacity: 0.1;
}

.testimonial-shape img:first-child {
  left: 5%;
}

.testimonial-shape img:nth-child(2) {
  right: 5%;
  bottom: 50px;
  top: auto;
}

/* ============================================================== 
    # Home Blog
=================================================================== */
.home-blog-style-one-item {
  position: relative;
}

.home-blog-style-one-item .info {
  padding: 30px;
  background: var(--white);
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

@media (min-width: 768px) {
  .home-blog-style-one-item .info {
    padding: 50px;
  }
}

.home-blog-style-one-item .content {
  padding: 30px 37px;
  position: relative;
  top: -100px;
  margin-bottom: -80px;
}

.home-blog-style-one-item .info .title {
  font-size: 32px;
  padding-right: 50px;
}

.home-blog-style-one-item .info ul.blog-meta {
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
}

.home-blog-style-one-item .info ul.blog-meta li {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding-right: 15px;
  text-transform: uppercase;
  font-size: 14px;
}

.home-blog-style-one-item .info .date {
  height: 80px;
  width: 80px;
  text-align: center;
  background: var(--color-primary);
  color: var(--white);
  line-height: 1.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  position: absolute;
  right: 30px;
  top: -40px;
}

.color-style-two .home-blog-style-one-item .info .date {
  background: var(--color-secondary);
}

.home-blog-style-one-item .info .date strong {
  display: block;
  font-size: 30px;
  font-weight: 800;
}

.home-blog-style-one-item .info ul.blog-meta li::after {
  position: absolute;
  right: 4px;
  top: 50%;
  content: "";
  height: 5px;
  width: 5px;
  background: var(--color-primary);
  border-radius: 50%;
  margin-top: -1px;
}

.color-style-two .home-blog-style-one-item .info ul.blog-meta li::after {
  background: var(--color-secondary);
}

.home-blog-style-one-item .info ul.blog-meta li:last-child::after {
  display: none;
}

.home-blog-style-one-item .info ul.blog-meta li,
.home-blog-style-one-item .info ul.blog-meta li a {
  font-weight: 600;
  color: var(--color-paragraph);
}

.home-blog-style-one-item .info ul.blog-meta li a:hover {
  color: var(--color-primary);
}

.color-style-two .home-blog-style-one-item .info ul.blog-meta li a:hover {
  color: var(--color-secondary);
}

a.btn-read-more {
  text-transform: uppercase;
  display: inline-block;
  margin-top: 5px;
  color: var(--color-primary);
  font-size: 17px;
}

.color-style-two a.btn-read-more {
  color: var(--color-secondary);
}

a.btn-read-more i {
  transform: rotate(-45deg);
  font-size: 14px;
  margin-left: 4px;
}

.grid-colum-3 .home-blog-style-one-item .info .title {
  padding: 0;
  font-size: 26px;
}

.grid-colum-3 .home-blog-style-one-item .info .date {
  right: auto;
}

.grid-colum-3 .home-blog-style-one-item .info {
  padding-top: 60px;
}

/* ============================================================== 
    # Menu Type
=================================================================== */
.menu-type-item .info {
  background: var(--white);
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  padding: 100px 50px;
  border-radius: 30px;
  padding-bottom: 70px;
  padding-top: 130px;
}

.menu-type-item .thumb {
  text-align: center;
}

.menu-type-item .thumb {
  height: 250px;
  position: relative;
  bottom: -80px;
  border-radius: 50%;
  background: var(--white);
  padding: 30px;
  border: 15px solid var(--dark);
  width: 250px;
  margin: auto;
}

.menu-type-area .row {
  --bs-gutter-x: 50px;
}

.menu-type-item {
  text-align: center;
  margin-top: -95px;
  color: var(--color-paragraph);
}

.menu-type-item .thumb img {
  box-shadow: -1px 3px 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  padding: 3px;
}

.menu-type-item h3 {
  color: var(--color-heading) !important;
  font-weight: 600;
  margin-bottom: 30px;
}

.menu-type-item .info p {
  display: inline-block;
  margin: 0;
  background: var(--bg-gray);
  color: var(--color-primary);
  padding: 7px 20px;
  border-radius: 30px;
  margin-top: 25px;
  text-transform: uppercase;
  font-weight: 700;
}

ul.menu-type-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.menu-type-list li {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin: 7px 0;
}

ul.menu-type-list li::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  height: 2px;
  width: 100%;
  border-top: 1px solid #cccccc;
  z-index: -1;
}

ul.menu-type-list li span {
  background: var(--white);
  padding-right: 15px;
}

ul.menu-type-list li b {
  background: var(--white);
  padding-left: 15px;
}

/* ============================================================== 
    # Fun Factor
=================================================================== */
.fun-facts-area {
  background-size: cover;
  background-position: center;
}

.fun-fact .counter {
  display: flex;
  justify-content: center;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 10px;
}

.fun-fact span.medium {
  font-weight: 700;
  font-size: 18px;
}

.fun-facts-area .item:last-child {
  border: none;
}

.fun-facts-area .item .fun-fact {
  display: inline-block;
  padding: 120px 60px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 200px;
  min-width: 240px;
  position: relative;
  z-index: 1;
}

.fun-facts-area .item .fun-fact::after {
  position: absolute;
  left: 50%;
  top: -9px;
  content: "";
  height: 20px;
  width: 20px;
  transform: translateX(-50%);
  background: var(--color-primary);
  border-radius: 50%;
  z-index: -1;
}

.fun-facts-area .item:nth-child(2n) .fun-fact::after {
  position: absolute;
  left: 50%;
  top: auto;
  bottom: -9px;
  content: "";
  height: 20px;
  width: 20px;
  transform: translateX(-50%);
  background: var(--color-primary);
  border-radius: 50%;
  z-index: -1;
}

/* ============================================================== 
    # Food Category
=================================================================== */

.food-category-area {
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: right top;
}

.food-category-item {
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 30px;
}

.food-category-item .info {
  background: var(--white);
  padding: 30px;
  overflow: hidden;
  position: relative;
  border-radius: 0 0 10px 10px;
}

.food-review .reviewer-avatar {
  display: inline-flex;
  align-items: center;
  padding: 10px;
}

.food-review .reviewer-avatar img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 2px solid var(--white);
  margin-left: -20px;
}

.food-review .reviewer-avatar {
  margin-left: 20px;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
}

.food-review .reviewer-avatar::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  left: -50px;
  background: #ebe9e6;
  z-index: -1;
  border-radius: 0 50px 50px 0;
  border-left: 3px solid var(--color-primary);
}

.color-style-two .food-review .reviewer-avatar::after {
  border-left: 3px solid var(--color-secondary);
}

.food-review .rating {
  position: absolute;
  right: 30px;
  top: 41px;
  font-size: 25px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: var(--color-heading);
}

.food-review .rating i {
  font-size: 20px;
  margin-right: 5px;
  color: #f7a105;
}

.food-category-item .info p {
  margin-bottom: 13px;
}

.food-category-item .info h4 {
  margin-bottom: 10px;
}

.food-category-item .thumb img {
  border-radius: 10px 10px 0 0;
}

/* ============================================================== 
    # Reservation
=================================================================== */

.reservation-time ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 30px;
}

.reservation-time li {
  display: inline-block;
  margin-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  padding-right: 30px;
}

.reservation-time li p {
  margin: 0;
}

.reservation-time li h4 {
  margin-bottom: 5px;
}

.reservation-time li:last-child {
  margin: 0;
  padding: 0;
  border: none;
}

.reservation-form .form-group .nice-select {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  color: #cccccc;
}

.reservation-form.light .form-group .nice-select {
  border: 1px solid #e7e7e7;
  color: var(--color-heading);
}

.reservation-form .form-group .nice-select ul.list li {
  color: var(--color-heading);
  padding: 8px 15px;
  min-height: 30px;
  line-height: 30px;
}

.reservation-form {
  padding: 70px 60px;
  position: relative;
  z-index: 1;
}

.reservation-form::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: var(--dark);
  z-index: -1;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
}

.reservation-area {
  position: relative;
  z-index: 1;
}

.reservation-area::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 120px;
  width: 100%;
  background: var(--white);
}

.reservation-form label {
  display: block;
  color: var(--white);
  width: 100%;
}

.reservation-form.light label {
  color: var(--color-paragraph);
}

.reservation-form .form-group {
  margin-bottom: 15px;
}

.reservation-form .input-group {
  margin-bottom: 15px;
}

.reservation-form select {
  width: 100%;
  min-height: 50px;
  /* border: none; */
  border-radius: 5px;
  padding: 10px;
  background: transparent;
  /* color: var(--white); */
  border: 1px solid #e7e7e7;
}

.reservation-form input,
.reservation-form input:focus {
  padding: 2px 15px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: var(--white);
  box-shadow: none;
  border-radius: 5px;
}

.reservation-form.light input,
.reservation-form.light input:focus {
  background: transparent;
  border: 1px solid #e7e7e7;
  color: var(--color-heading);
}

.reservation-form .input-group span.input-group-addon {
  background: var(--color-primary);
  color: var(--white);
  padding: 0 20px;
  line-height: 50px;
  border-radius: 0 5px 5px 0;
  border: none;
}

.color-style-two .reservation-form .input-group span.input-group-addon {
  background: var(--white);
  color: var(--color-secondary);
}

.reservation-form.light .input-group span.input-group-addon {
  background: var(--white);
  color: var(--color-heding);
  border: 1px solid #e7e7e7;
}

.reservation-form.light .input-group span.input-group-addon i {
  color: var(--color-primary);
}

/* .reservation-form .input-group input {
  border-right: none;
  border-radius: 5px 0 0 5px;
} */

.reservation-form > i {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 70px;
  text-align: center;
  background: var(--color-primary);
  color: var(--white);
  font-weight: 600;
  font-size: 30px;
  border-radius: 50%;
  position: absolute;
  top: -45px;
  border: 5px solid;
}

.reservation-form h3 {
  margin-bottom: 30px;
}

.reservation-form.light button {
  margin-top: 15px;
}

.reservation-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.reservation-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.reservation-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.reservation-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.reservation-form.light input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666666;
}

.reservation-form.light input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666666;
}

.reservation-form.light input:-ms-input-placeholder {
  /* IE 10+ */
  color: #666666;
}

.reservation-form.light input:-moz-placeholder {
  /* Firefox 18- */
  color: #666666;
}

.reservation-form select option {
  color: var(--color-heading);
  padding: 20px;
}

.reservation-form button::after {
  background: var(--white);
}

.reservation-form.light button::after {
  background: var(--dark);
}

.reservation-form button:hover {
  color: var(--color-heading);
}

.reservation-form.light button:hover {
  color: var(--white);
}

/* ============================================================== 
    # Menu Category
=================================================================== */
h2.flex-title {
  display: flex;
  align-items: flex-start;
  padding-right: 140px;
}

h2.flex-title img {
  margin-right: 8px;
  position: relative;
  top: 2px;
}

.food-cat-carousel a {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: 10px;
}

.food-cat-carousel img {
  transition: all 0.35s ease-in-out;
}

.food-cat-carousel a:hover img {
  transform: scale(1.1);
}

.food-cat-carousel .overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 30px;
  z-index: 1;
}

.food-cat-carousel .overlay h5 {
  margin: 0;
  color: var(--white);
}

.food-cat-carousel .overlay span {
  color: #e7c28a;
}

.food-cat-carousel a::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 60%;
  width: 100%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%)
    repeat scroll 0 0;
}

.food-swiper-nav {
  position: absolute;
  right: 0;
  top: 130px;
  display: flex;
  width: 100px;
  justify-content: space-between;
  align-items: center;
}

.food-swiper-nav > div {
  height: 40px;
  width: 40px;
  background: var(--color-primary);
  z-index: 9;
  position: relative;
  border-radius: 50%;
}

.food-swiper-nav > div::after {
  position: absolute;
  top: 7px;
  content: "";
  height: 100%;
  width: 100%;
  font-family: "Font Awesome 5 Pro";
  text-align: center;
  color: var(--white);
}

.food-swiper-nav .food-cat-next::after {
  content: "\f105";
}

.food-swiper-nav .food-cat-prev::after {
  content: "\f104";
}

/* ============================================================== 
    # Offer Product
=================================================================== */

.offer-product-thumb {
  padding-right: 100px;
  position: relative;
  padding-bottom: 50px;
}

.food-quick-info {
  background: var(--white);
  padding: 50px;
  position: absolute;
  right: -30px;
  bottom: 0;
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.food-quick-info::after {
  position: absolute;
  left: -20px;
  top: 30px;
  content: "";
  height: 50px;
  width: 30px;
  background: var(--white);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
}

.food-quick-info .rating {
  justify-content: left;
  margin-bottom: 20px;
  color: var(--color-primary);
}

.color-style-two .food-quick-info .rating {
  color: var(--color-secondary);
}

.food-quick-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 20px;
}

.food-quick-info ul li {
  font-weight: 600;
  list-style: disc;
}

.food-quick-info .price {
  margin-top: 20px;
  font-weight: 800;
}

.offer-product-info .title {
  margin-bottom: 30px;
}

.offer-product-info .btn {
  margin-top: 10px;
}

.offer-product-area {
  background-size: 25%;
  background-repeat: no-repeat;
  background-position: left bottom;
  margin-top: -30px;
}

/* ============================================================== 
    # Contact
=================================================================== */
.contact-form-style-one {
  box-shadow: 0px 15px 60px -10px rgb(109 117 143 / 33%);
  padding: 60px 80px;
  border-radius: 10px;
  position: relative;
  background: var(--white);
  z-index: 9;
}

.contact-form-style-one input,
.contact-form-style-one textarea,
.contact-form-style-one input:focus,
.contact-form-style-one textarea:focus {
  margin-bottom: 15px;
  padding: 15px;
  border: none;
  font-size: 18px;
  border-radius: 6px;
  background: #f3f3f3;
}

.contact-form-style-one input:focus,
.contact-form-style-one textarea:focus {
  box-shadow: inherit;
}

.contact-form-style-one textarea {
  min-height: 180px;
}

.contact-form-style-one button {
  display: inline-block;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  border-radius: 6px;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  font-size: 17px;
  letter-spacing: 0;
  position: relative;
  z-index: 1;
  color: var(--white);
  border: none;
  background: var(--color-primary);
  padding: 16px 52px;
}

.contact-form-style-one button::after {
  position: absolute;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: -5%;
  content: "";
  height: 150px;
  width: 115%;
  z-index: -1;
  background-color: var(--dark);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateY(-45%) skew(25deg) scale(0);
  transform: translateY(-45%) skew(25deg) scale(0);
}

.contact-form-style-one button:hover::after {
  -webkit-transform: translateY(-45%) skew(25deg) scale(1.2);
  transform: translateY(-45%) skew(25deg) scale(1.2);
}

.contact-form-style-one button:hover {
  color: var(--white);
}

img.loader {
  margin-left: 8px;
}

.contact-style-one-info ul li {
  text-align: center;
  padding: 30px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #eeeeee;
  border-radius: 10px;
}

.contact-style-one-info ul li i {
  display: inline-block;
  font-size: 35px;
  margin-bottom: 20px;
  background: var(--color-primary);
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  color: var(--white);
  border-radius: 50%;
}

.contact-style-one-info ul li .title {
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 800;
}

.contact-style-one-info ul li:nth-child(2) i {
  background: #0b57e3;
}

.contact-style-one-info ul li:nth-child(3) i {
  background: #57b33e;
}

.contact-style-one-info ul li:last-child::after {
  border: none;
}

.contact-form-style-one .sub-title {
  margin-bottom: 10px;
}

.contact-style-one-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  margin-bottom: 60px;
}

.contact-style-one-info ul li p {
  margin: 0;
}

.contact-style-one-area {
  position: relative;
  z-index: 1;
}

.contact-style-one-area::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 50%;
  width: 100%;
  background: url(../img/shape/map.png);
  z-index: -1;
  background-size: contain;
  opacity: 0.8;
}

.contact-style-one-info ul li img {
  height: 65px;
  margin-bottom: 25px;
}

/* ============================================================== 
    # Maps
=================================================================== */
.google-maps iframe {
  width: 100%;
  height: 600px;
}

/* ============================================================== 
    # 404 page
=================================================================== */

.error-page-area {
  position: relative;
  z-index: 1;
}

.error-page-area .shape-left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 20%;
  background-position: left !important;
  background-repeat: no-repeat !important;
  z-index: -1;
  opacity: 0.3;
  background-size: contain !important;
}

.error-page-area .shape-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 20%;
  background-position: right !important;
  background-repeat: no-repeat !important;
  z-index: -1;
  opacity: 0.3;
  background-size: contain !important;
}

.error-box h1 {
  font-size: 150px;
  line-height: 110px;
  font-weight: 800;
  margin-bottom: 40px;
  text-shadow: 3px 3px #c0c0c0;
}

.error-box h2 {
  font-weight: 700;
  margin-bottom: 20px;
}

.error-box p {
  padding: 0 10%;
}

/* ============================================================== 
    # Footer
=================================================================== */

.footer-style-one {
  padding: 80px;
  padding-top: 30px;
  position: relative;
  z-index: 2;
}

.footer-style-one::after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  height: 30px;
  background: url(../img/shape/8.png);
  top: -11px;
  background-size: 500px;
  background-repeat: repeat-x;
  background-position: top center;
}

footer .about {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding-right: 30px;
}

footer .widget-title {
  margin-bottom: 30px;
  font-weight: 700;
}

footer .contact ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

footer .contact ul li {
  display: flex;
  margin-top: 20px;
}

footer .contact ul li i {
  display: inline-block;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 35px;
  background: var(--color-primary);
  border-radius: 5px;
  margin-right: 15px;
  color: var(--white);
}

.color-style-two footer .contact ul li i {
  background: var(--color-secondary);
}

footer .contact ul li a {
  font-weight: 400;
}

footer .contact ul li a:hover {
  color: var(--white);
}

footer .contact ul li .content {
  position: relative;
  top: -7px;
}

footer .contact ul li:last-child {
  align-items: center;
}

footer .contact ul li:last-child .content {
  top: 0;
}

footer .link li {
  margin-top: 10px;
}

.color-style-two footer .link li a:hover {
  color: var(--white);
}

footer .link li:first-child {
  margin-top: 0;
}

footer a {
  font-weight: 400;
}

footer .bg-dark,
footer .bg-dark li,
footer .bg-dark a,
footer .bg-dark p,
footer.bg-dark,
footer.bg-dark li,
footer.bg-dark a,
footer.bg-dark p {
  color: #cccccc;
}

footer .bg-dark a:hover {
  color: var(--white);
}

ul.footer-social li {
  display: inline-block;
}

ul.footer-social li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: #333333;
  text-align: center;
  margin-right: 8px;
  margin-top: 10px;
  color: var(--white);
}

ul.footer-social li a:hover {
  background: var(--color-primary);
}

.color-style-two ul.footer-social li a:hover {
  background: var(--color-secondary);
  color: var(--white);
}

.f-item.newsletter form {
  position: relative;
}

.f-item.newsletter form input {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 0;
  padding: 0;
}

.f-item.newsletter form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.f-item.newsletter form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.f-item.newsletter form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.f-item.newsletter form input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.f-item.newsletter form button {
  padding: 0;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
}

.f-item.newsletter form button i {
  font-weight: 900;
}

.footer-item fieldset {
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 10px;
}

.footer-item fieldset input {
  min-height: auto;
  position: relative;
  margin-right: 10px;
}

.footer-item fieldset label {
  margin: 0;
}

.footer-bottom img {
  height: 60px;
}

.footer-bottom {
  padding: 50px 0;
  position: relative;
  z-index: 1;
}

.footer-bottom::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  height: 200%;
  width: 100%;
  background: var(--dark-secondary);
  z-index: -1;
}

.footer-bottom .row {
  align-items: center;
}

.footer-bottom p {
  margin: 0;
  color: #cccccc;
}

.footer-bottom-two ul li {
  display: inline-block;
}

.footer-bottom-two .text-end ul li {
  margin-left: 20px;
}

.footer-bottom-two {
  background: var(--dark-secondary);
  padding: 19px 0;
}

.footer-bottom-two p {
  margin: 0;
}

.footer-bottom-two ul li a:hover {
  color: var(--white);
}

.footer-top img {
  height: 60px;
}

.footer-top {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}

.footer-top::after {
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  z-index: -1;
}

.footer-top a {
  display: inline-block;
  background: var(--dark);
  padding: 0 30px;
}

.footer-bottom-shape {
  position: absolute;
  left: 0;
  bottom: 0;
}

.footer-style-two .footer-bottom-shape {
  z-index: -1;
}

.footer-bottom-shape img {
  height: auto;
  max-width: 35%;
  opacity: 0.4;
}

.footer-style-two {
  position: relative;
  z-index: 1;
}

.footer-logo {
  position: relative;
  z-index: 1;
}

/* ============================================================== 
     # Dark Layout CSS 
=================================================================== */

.bg-dark a {
  color: var(--white);
}

.bg-dark .bg-gray {
  background: var(--dark-secondary);
}

.bg-dark,
.bg-dark-secondary,
.bg-dark p,
.bg-dark-secondary p {
  color: #cccccc;
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6 {
  color: var(--white);
}

.bg-dark .about-style-one-info img {
  filter: brightness(0) invert(1);
  opacity: 0.3;
}

.bg-dark .about-style-one-info {
  background: var(--dark-secondary);
}

.bg-dark .opening-hour-items .text-fixed {
  color: var(--white);
}

.bg-dark .opening-hours-info {
  background: var(--dark-secondary);
  box-shadow: none;
}

.bg-dark ul.opening-hours-table h6 {
  background: var(--dark-secondary);
}

.bg-dark ul.opening-hours-table li span {
  background: var(--dark-secondary);
  color: var(--white);
}

.bg-dark .opening-hour-items .shape img {
  filter: brightness(0) invert(1);
}

.bg-dark .testimonial-thumb img {
  box-shadow: none;
}

.bg-dark .testimonial-shape img {
  filter: brightness(0) invert(1);
  opacity: 0.4;
}

.bg-dark .footer-style-one.bg-dark {
  background: #343434 !important;
}

.bg-dark .opening-hour-items .text-fixed {
  color: var(--white);
}

.bg-dark .opening-hours-info {
  background: var(--dark-secondary);
  box-shadow: none;
}

.bg-dark ul.opening-hours-table h6 {
  background: var(--dark-secondary);
}

.bg-dark ul.opening-hours-table li span {
  background: var(--dark-secondary);
  color: var(--white);
}

.bg-dark .opening-hour-items .shape img {
  filter: brightness(0) invert(1);
}

.bg-dark .testimonial-thumb img {
  box-shadow: none;
}

.bg-dark .testimonial-shape img {
  filter: brightness(0) invert(1);
  opacity: 0.4;
}

.bg-dark .footer-style-one.bg-dark {
  background: #2e2d2d !important;
}

.bg-dark .home-blog-style-one-item .info {
  background: var(--dark-secondary);
}

.bg-dark .home-blog-style-one-item .info ul.blog-meta li,
.bg-dark .home-blog-style-one-item .info ul.blog-meta li a {
  color: #cccccc;
}

.bg-dark .home-blog-style-one-item .info ul.blog-meta li::after {
  background: #f3b75a;
}

.bg-dark.color-style-two
  .home-blog-style-one-item
  .info
  ul.blog-meta
  li::after {
  background: var(--color-secondary);
}

.bg-dark ul.footer-social li a {
  background: #3f3f3f;
}

.bg-dark .footer-style-one::after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  height: 30px;
  background: url(../img/shape/8-alt.png);
  top: -11px;
  background-size: 500px;
  background-repeat: repeat-x;
  background-position: top center;
}

/* Home Two */
.bg-dark .food-category-item .info {
  background: var(--dark-secondary);
}

.bg-dark .food-review .reviewer-avatar::after {
  background: #414141;
}

.bg-dark .food-review .rating {
  color: var(--white);
}

.bg-dark .author-details .author-content img {
  filter: brightness(0) invert(1);
}

.bg-dark
  .tab-content.food-menu-tab-content.style-two
  ul.meal-items
  li
  .content
  .top
  h4 {
  background: var(--dark-secondary);
}

.bg-dark
  .tab-content.food-menu-tab-content.style-two
  ul.meal-items
  li
  .content
  .top
  .price {
  background: var(--dark-secondary);
}

.bg-dark
  .tab-content.food-menu-tab-content.style-two
  ul.meal-items
  li
  .content
  .bottom
  p {
  color: #cccccc;
}

.bg-dark .reservation-area::before {
  background: var(--dark);
}

.bg-dark .bg-dark .footer-style-two.default-padding {
  padding-top: 0;
}

.bg-dark .reservation-area {
  background-position: bottom center !important;
}

.bg-dark .curve-text .thumb {
  background: var(--dark);
}

.bg-dark .food-category-item a.btn-read-more {
  color: var(--white);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 12px 32px;
  border-radius: 40px;
}

.bg-dark .food-category-item a.btn-read-more:hover {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.bg-dark.color-style-two
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet::after {
  border-color: var(--white);
}

.bg-dark.color-style-two
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
  span.swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  border-color: var(--white);
  opacity: 0.6;
}

/* Home Three */
.bg-dark .reservation-style-two label {
  color: var(--white);
}

.bg-dark .nice-select .current {
  color: #cccccc;
}

.bg-dark .product-style-two .product .product-contents {
  background: var(--dark-secondary);
}

.bg-dark .product-style-two .product .product-contents .price del {
  color: #cccccc;
}

.bg-dark .food-quick-info {
  background: #363434;
}

.bg-dark .food-quick-info::after {
  background: #363434;
}

.bg-dark .food-quick-info .price del {
  color: #cccccc;
}

.bg-dark .fun-facts-area {
  padding-top: 0;
}

.bg-dark .top-bar-style-one.bg-transparent p {
  color: var(--white);
}

/* Home Four */

.bg-dark .reservation-form.light::after {
  background: var(--dark-secondary);
  border: none;
}

.bg-dark .reservation-form.light input,
.bg-dark .reservation-form.light input:focus {
  border-color: rgba(255, 255, 255, 0.3);
  color: var(--white);
}

.bg-dark .reservation-form.light .form-group .nice-select {
  border-color: rgba(255, 255, 255, 0.3);
  color: #cccccc;
}

.bg-dark .reservation-form.light .form-group .nice-select span.current {
  color: #cccccc;
}

.bg-dark .reservation-form.light button::after {
  background: var(--white);
}

.bg-dark .reservation-form.light button:hover {
  color: var(--color-heading);
}

.bg-dark .reservation-form.light input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.bg-dark .reservation-form.light input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.bg-dark .reservation-form.light input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.bg-dark .reservation-form.light input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.bg-dark .nav.nav-tabs.food-menu-nav.style-three button {
  color: var(--white);
}

.bg-dark .food-menu-style-three .info {
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .food-menu-style-three .thumb .d-flex {
  border-color: rgba(255, 255, 255, 0.2);
  background: var(--dark);
}

.bg-dark .food-menu-style-three .thumb .d-flex::after {
  position: absolute;
  right: -15px;
  bottom: 0;
  content: "";
  height: 15px;
  width: 15px;
  background: url('data:image/svg+xml,<svg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-11 h-11"><path d="M11 1.54972e-06L0 0L2.38419e-07 11C1.65973e-07 4.92487 4.92487 1.62217e-06 11 1.54972e-06Z" fill="%231b1b1b"></path></svg>');
  transform: rotate(-90deg);
}

.bg-dark .cart-btn-border {
  background: var(--color-primary);
}

.bg-dark .food-menu-style-three .thumb .d-flex .price del {
  color: #cccccc;
}

.bg-dark .download-app-items {
  background-color: var(--dark-secondary) !important;
}

/* Home Five */
.bg-dark ul.card-info {
  background: #363434 !important;
}

.bg-dark .services-style-one {
  background: #363434;
}

.bg-dark .services-style-one ul li span {
  background: #363434;
}

.bg-dark .services-style-one ul li strong {
  background: #363434;
}

.bg-dark .services-style-one .btn {
  background: transparent;
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.3);
}

.bg-dark .services-style-one .btn:hover {
  background: var(--white);
  border-color: var(--white);
  color: var(--color-heading);
}

.bg-dark .services-style-one .btn::after {
  background: var(--white);
}

.bg-dark .tab-content.food-style-four-content {
  background: #363434;
}

.bg-dark .nav.nav-tabs.food-menu-nav.style-three.four button::after {
  background: #363434;
}

.bg-dark .food-menus-item ul.meal-items li .content .top h4 {
  background: var(--dark);
}

.bg-dark
  .food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top
  .price {
  background: #363434;
}

.bg-dark
  .food-style-four-content
  .food-menus-item
  ul.meal-items
  li
  .content
  .top
  .price
  span {
  background: var(--color-secondary);
  color: var(--white);
}

.bg-dark .food-menus-item ul.meal-items li .content .bottom p {
  color: #cccccc;
}

.bg-dark .food-menus-item ul.meal-items li .content .top .price {
  background: var(--dark);
}

.bg-dark ul.meal-items li .content .top h4 {
  background: var(--dark);
}

.bg-dark ul.meal-items li .content .top .price {
  background: var(--dark);
}

.bg-dark .food-menu-items ul.meal-items li .content .top h4 {
  background: var(--dark-secondary);
}

.bg-dark .food-menu-items ul.meal-items li .content .top .price {
  background: var(--dark-secondary);
}

.bg-dark .food-menu-area.shape-less::after {
  background: transparent;
}

.bg-dark .food-menu-area.shape-less::before {
  background: url(../img/shape/3-light.png);
}

.bg-dark .shape-less .nav.nav-tabs.food-menu-nav button {
  color: var(--white);
}

.bg-dark .shape-less .nav.nav-tabs.food-menu-nav {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}

/* Blog */
.bg-dark .blog-area .item {
  background: transparent;
}

.bg-dark .blog-area .meta li a {
  color: #cccccc;
}

.bg-dark .blog-area.single .item .blog-item-box .meta li i {
  color: #bb9760;
}

.bg-dark .blog-area blockquote {
  background: #363434;
}

.bg-dark .post-author {
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .post-tags.share .social ul li a {
  background: #363434;
}

.bg-dark .post-tags.share .social ul li a:hover {
  background: var(--color-primary);
}

.bg-dark .comments-area .comment-item {
  background: #363434;
}

.bg-dark .comments-area .comment-item .content .title span {
  color: #cccccc;
}

.bg-dark .comments-area .comments-form input {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .comments-area .comments-form textarea {
  color: var(--white);
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .blog-comments {
  border-color: rgba(255, 255, 255, 0.3);
}

.bg-dark .post-pagi-area a .icon i {
  color: var(--white);
}

.bg-dark .comments-area .comment-item .content .reply a {
  background: var(--color-primary);
}

.bg-dark .sidebar .sidebar-item {
  background: #363434;
}

.bg-dark .sidebar .sidebar-item.recent-post li .info a {
  color: var(--white);
}

.bg-dark .sidebar .sidebar-item.recent-post li span {
  color: #cccccc;
}

.bg-dark .sidebar .sidebar-item.archives ul li a {
  color: #cccccc;
}

.bg-dark .sidebar .sidebar-item.tags ul li a {
  background: transparent;
  color: #cccccc;
}

.bg-dark .sidebar .sidebar-item.category li a {
  color: #cccccc;
}

.bg-dark .sidebar .sidebar-item.category li a span {
  color: var(--white);
  background: #4b4b4b;
}

.bg-dark .comments-area .comments-form input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.bg-dark .comments-area .comments-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.bg-dark .btn.btn-theme::after {
  background: var(--white);
}

.bg-dark .menu-type-item .btn.btn-theme::after {
  background: var(--dark);
}

.bg-dark .btn.btn-theme:hover {
  color: var(--color-heading);
}

.bg-dark .menu-type-item .btn.btn-theme:hover {
  color: var(--white);
}

.bg-dark .post-tags.share .tags a {
  background: #363434;
  color: var(--white);
}

.bg-dark .post-tags.share .tags a:hover {
  background: var(--color-primary);
}

.bg-dark .blog-area.full-blog .item {
  background: #363434;
  box-shadow: none;
}

.bg-dark .blog-area.full-blog.single .item {
  background: transparent;
}

.bg-dark .blog-area.full-blog .meta li i {
  color: #bb9760;
}

.bg-dark .price {
  color: #bb9760;
}

.bg-dark .product .product-contents {
  background: #363434;
  box-shadow: none;
}

.bg-dark .price del {
  color: #847a7a;
}

.bg-dark .shop-listing-contentes .text-right select {
  color: #cccccc;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 5px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  padding-right: 40px;
}

.bg-dark
  .single-product-bottom-info
  .tab-content.tab-content-info
  .tab-pane
  ul
  li::after {
  color: #cccccc;
}

.bg-dark .swiper .product .product-contents {
  border: none;
}

.bg-dark .single-product-bottom-info .nav.nav-tabs button {
  background: transparent;
  color: var(--white);
}

.bg-dark .single-product-bottom-info .nav-tabs .nav-link.active::before {
  background: #bb9760;
}

.bg-dark .single-product-bottom-info .nav-tabs {
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .product-thumb .carousel-indicators .item {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .product-details .product-thumb .product-item {
  background: #363434;
}

.bg-dark .product-stock span {
  background: var(--white);
}

.bg-dark .product-purchase-list input,
.product-purchase-list > a.btn,
.bg-dark .product-purchase-list .shop-action {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: var(--white);
}

.bg-dark .single-product-contents .shop-action li a {
  background: transparent;
  border-color: rgba(255, 255, 255, 0.2);
  color: #bb9760;
}

.bg-dark .product-estimate-delivary {
  background: var(--dark-secondary);
}

.bg-dark .product-estimate-delivary strong {
  color: var(--white);
}

@media (max-width: 767px) {
  .bg-dark .single-product-bottom-info .nav.nav-tabs button {
    border-top: 1px solid rgba(255, 255, 255, 0.3) !important;
  }

  .bg-dark .single-product-bottom-info .nav.nav-tabs button:first-child {
    border: none !important;
  }

  .bg-dark .single-product-bottom-info .nav.nav-tabs button.active,
  .bg-dark .single-product-bottom-info .nav.nav-tabs button:hover {
    color: #bb9760;
  }
}

.bg-dark .validtheme-shop-area button::after,
.bg-dark .single-product-bottom-info form button::after {
  background: var(--white);
}

.bg-dark .validtheme-shop-area button:hover,
.bg-dark .single-product-bottom-info form button:hover {
  color: var(--color-heading);
}

.bg-dark .review-form input,
.bg-dark .review-form textarea {
  background: var(--dark-secondary);
  color: var(--white);
}

.bg-dark .review-form input::-webkit-input-placeholder,
.bg-dark .review-form textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.bg-dark .review-form input::-moz-placeholder,
.bg-dark .review-form textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.bg-dark .review-form input:-ms-input-placeholder,
.bg-dark .review-form textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.bg-dark .review-form input:-moz-placeholder,
.bg-dark .review-form textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.bg-dark .single-product-bottom-info td {
  color: #cccccc;
}

.bg-dark .single-product-bottom-info table {
  border-color: rgba(255, 255, 255, 0.2);
}

.bg-dark .chef-single-area .bottom-info {
  background: var(--dark) !important;
}

.bg-dark .chef-list-item span {
  color: #cccccc;
}

.bg-dark .chef-list-item ul li::after {
  background: var(--dark);
}

.bg-dark .chef-single-area .right-info .social .share-link > i {
  background: #545151;
}

.bg-dark .chef-single-area .right-info .social ul li a {
  color: var(--color-heading);
}

.bg-dark .opening-hours-info .reservation-form label {
  color: var(--white);
}

.bg-dark .opening-hours-info .reservation-form input,
.bg-dark .opening-hours-info .reservation-form input:focus {
  border-color: rgba(255, 255, 255, 0.3);
  color: var(--white);
}

.bg-dark
  .opening-hours-info
  .reservation-form
  .form-group
  .nice-select
  .current {
  color: var(--white);
}

.bg-dark .opening-hours-info .reservation-form .form-group .nice-select {
  border-color: rgba(255, 255, 255, 0.3);
}

.bg-dark .opening-hours-info .reservation-form button::after {
  background: var(--white);
}

.bg-dark .opening-hours-info .reservation-form button:hover {
  color: var(--color-heading);
}

.bg-dark
  .opening-hours-info
  .reservation-form
  input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

.bg-dark .opening-hours-info .reservation-form input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

.bg-dark .opening-hours-info .reservation-form input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

.bg-dark .opening-hours-info .reservation-form input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.bg-dark .reservation-style-two .nice-select .current {
  color: #666666;
}

/* Contact Dark */
.bg-dark .contact-form-style-one {
  box-shadow: none;
  background: var(--dark-secondary);
}

.bg-dark .contact-form-style-one input,
.bg-dark .contact-form-style-one textarea,
.bg-dark .contact-form-style-one input:focus,
.bg-dark .contact-form-style-one textarea:focus {
  background: var(--dark);
  color: var(--white);
}

.bg-dark .contact-form-style-one button::after {
  background: var(--white);
}

.bg-dark .contact-form-style-one button:hover {
  color: var(--color-heading);
}

.bg-dark .contact-style-one-area::before {
  opacity: 0.1;
}

.bg-dark .contact-style-one-info ul li {
  background: #363434;
  border: none;
}

/* ============================================================== 
    # Responsive CSS
=================================================================== */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-style-four-content h2 {
    font-size: 80px;
  }

  .menu-type-area {
    padding-top: 70px;
  }

  .menu-type-single {
    margin-top: 50px;
  }

  /* About */
  .about-style-one-area {
    overflow: hidden;
  }

  /* About Two */

  .author-info {
    display: block;
  }

  .author-details {
    margin-top: 30px;
    display: none;
  }

  .shape-overlay img {
    height: 150px;
  }

  .shape-overlay {
    bottom: -70px;
  }

  .about-style-two-thumb::after {
    height: 450px;
    width: 450px;
  }

  .about-style-thumb-box > img {
    height: 150px;
    right: 0;
  }

  .about-style-thumb-box .curve-text {
    left: -30px;
  }

  /* About Three */
  .item-grid-two-colum {
    grid-template-columns: 1fr;
  }

  /* Food Menu */

  .food-menu-tab-content .discount-badge {
    right: auto;
    left: 120px;
    bottom: -70px;
    top: auto;
  }

  .tab-content.food-menu-tab-content .info {
    padding: 120px;
  }

  ul.meal-items li .content .bottom {
    display: block;
  }

  ul.meal-items li .content .bottom .right {
    text-align: left;
  }

  ul.meal-items li {
    align-items: inherit;
  }

  ul.meal-items li .thumbnail img {
    min-width: 60px;
  }

  .tab-content.food-menu-tab-content .thumb {
    height: 450px;
  }

  .food-menu-area::after {
    height: 55%;
  }

  ul.meal-type {
    margin-bottom: 25px;
  }

  ul.meal-type li {
    font-size: 14px;
    margin-left: 5px;
  }

  .food-menus-item .info {
    padding-left: 80px;
  }

  .food-menus-item .discount-badge strong {
    font-size: 24px;
  }

  .food-menus-item .discount-badge {
    font-size: 18px;
    height: 150px;
    width: 150px;
    right: -75px;
  }

  .food-menus-item:nth-child(2n) .info {
    padding-right: 80px;
  }

  .food-menus-item:nth-child(2n) .discount-badge {
    left: -75px;
  }

  /* Fun Factor */
  .fun-facts-area .item .fun-fact {
    padding: 120px 30px;
    min-width: 220px;
  }

  .offer-product-thumb {
    padding-right: 30px;
  }

  /* Top Feature */
  .top-feature-style-one-area::after {
    display: none;
  }

  .opening-hours-hightlight {
    background: var(--color-primary);
    padding: 120px;
    margin-top: 60px;
  }

  .top-feature-style-one-info .align-center {
    align-items: center;
  }

  .top-feature-style-one-info .pl-50 {
    padding-left: 0;
  }

  /* Opening Hours */
  .opening-hour-items .text-fixed {
    font-size: 120px;
  }

  .opening-hours-info {
    padding: 50px;
  }

  .opening-hours-thumb .mbYTP_wrapper {
    max-width: 140%;
  }

  /* Testimonial */
  .testimonial-thumb img {
    height: 80px;
  }

  .testimonial-thumb img:nth-child(3) {
    height: 110px;
  }

  /* Chef Details */
  .chef-single-area .chef-content-top {
    margin: 0;
    bottom: 0;
  }

  .chef-single-area .chef-content-top .right-info {
    margin-bottom: 120px;
  }
}

/* Tablet Layout Custom */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

@media screen and (max-width: 991px) {
  /* global reset - start */
  .order-last {
    order: 0;
  }

  /* Food Menu Three */
  .food-menu-style-three .thumb .d-flex {
    display: block !important;
  }

  .food-menu-style-three .thumb .d-flex .food-review {
    margin-bottom: 15px;
  }

  .food-menu-style-three ul li:first-child::after {
    display: block;
  }

  .food-menu-style-three ul li:first-child {
    margin-left: 7px;
    padding-left: 15px;
  }

  .food-menu-style-three ul {
    margin-left: -7px;
  }
}

/* Tablet Layout: 768px.*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Topbar */

  .top-bar-area {
    background: var(--dark) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .top-bar-area.bg-transparent {
    background: var(--color-primary) !important;
  }

  .top-bar-area .item-flex {
    justify-content: center;
  }

  .top-bar-area .text-end {
    margin-top: 10px;
    display: none;
  }

  .topbar-two-items .logo {
    display: none;
  }

  .top-bar-style-two {
    padding: 15px 0;
  }

  .topbar-two-items {
    justify-content: center;
  }

  .topbar-two-items .topbar-info {
    margin: 0 25px;
  }

  /* Banner */

  .banner-area.banner-style-one .content {
    padding: 120px 0;
  }

  .banner-style-one h2 {
    font-size: 70px;
  }

  .banner-area.banner-style-one .video-play-button {
    display: none;
  }

  .banner-area.banner-style-two .content {
    padding: 120px 0;
  }

  .banner-style-two h2 {
    font-size: 50px;
  }

  /* Banner Three */

  .banner-style-three-content,
  .banner-style-three-content div {
    height: auto;
  }

  .banner-style-three-content {
    padding: 150px 0;
  }

  .banner-style-three-area {
    height: auto;
  }

  .banner-style-three-content h2 {
    margin-top: -15px;
  }

  /* Banner Four */
  .banner-style-four-area,
  .banner-style-four-area div {
    height: auto;
  }

  .banner-style-four-content {
    padding: 120px 0;
  }

  /* Banner Five */
  .banner-area.banner-style-five {
    padding: 120px 0;
  }

  /* About */
  .about-style-one-info {
    margin: 0;
    position: inherit;
    padding: 0;
    box-shadow: none;
  }

  .bg-dark .about-style-one-info {
    background: transparent;
  }

  .about-thumb {
    position: inherit;
    min-height: 400px;
    width: 100%;
    grid-template-columns: 1fr;
    top: 0;
    margin-bottom: 50px;
  }

  .about-thumb .item:nth-child(2) {
    display: none;
  }

  .about-thumb .item {
    width: 100%;
    height: 100%;
    background-position: top center;
    max-width: 720px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

  .about-style-one-info img {
    display: none;
  }

  .about-style-one-area {
    margin-bottom: 0;
  }

  /* About Two */
  .about-style-thumb-box > img {
    right: 0;
  }

  .about-style-thumb-box .curve-text {
    bottom: 50px;
  }

  .about-style-thumb-box {
    margin-bottom: 60px;
  }

  /* About Three */
  .about-style-three-thumb {
    padding-right: 60px;
    padding-bottom: 30px;
    margin-bottom: 40px;
  }

  .item-grid-two-colum {
    grid-template-columns: 1fr;
  }

  .about-style-three-thumb img:first-child {
    width: 100%;
  }

  /* About Four */
  .thumb-style-two {
    margin-bottom: 50px;
  }

  ul.card-info {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .thumb-style-two img {
    width: 100%;
  }

  /* Services */
  .services-style-one-area .site-heading {
    padding-right: 130px;
  }

  .services-swiper-nav {
    top: -105px;
  }

  .services-style-one .info {
    padding: 45px 30px;
  }

  .services-style-one ul li {
    font-size: 14px;
  }

  /* Top Feature */
  .top-feature-style-one-area::after {
    width: 100%;
    height: 50%;
    top: auto;
    bottom: 0;
  }

  .top-feature-style-one-thumb img {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .feature-style-two-items {
    margin-top: 50px;
  }

  /* Menu Type */
  .menu-type-single {
    margin-top: 50px;
  }

  .menu-type-area {
    padding-top: 70px;
  }

  .nav.nav-tabs.food-menu-nav.style-three.four button {
    padding: 0 35px;
  }

  .food-menu-style-four-items .animate-up-down {
    max-width: 100px;
    right: -32px;
  }

  /* Offer Product */

  .offer-product-thumb img {
    margin-bottom: 30px;
  }

  .food-quick-info {
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .offer-product-thumb img {
    width: 100%;
  }

  .offer-product-thumb {
    padding-right: 0;
    padding-bottom: 0;
  }

  .offer-product-info {
    margin-top: 50px;
    text-align: center;
  }

  .offer-product-info .sub-heading::before {
    display: none;
  }

  /* Fun Factor */
  .fun-facts-area .item .fun-fact {
    display: block;
    height: 320px;
    width: 320px;
    margin: 30px auto;
  }

  .fun-facts-area .item:nth-child(2n) .fun-fact::after {
    bottom: auto;
    top: -9px;
  }

  .fun-facts-area .item-inner {
    margin: -30px 0;
  }

  /* Food Menu */

  .food-menu-tab-content .discount-badge {
    right: auto;
    left: 60px;
    bottom: -70px;
    top: auto;
  }

  .tab-content.food-menu-tab-content .info {
    padding: 60px;
  }

  ul.meal-items li .content .bottom {
    display: block;
  }

  ul.meal-items li .content .bottom .right {
    text-align: left;
  }

  ul.meal-items li {
    align-items: inherit;
  }

  ul.meal-items li .thumbnail img {
    min-width: 60px;
  }

  ul.meal-items li .content .top {
    align-items: start;
  }

  .tab-content.food-menu-tab-content .thumb {
    height: 400px;
  }

  .food-menu-area::after {
    height: 55%;
  }

  ul.meal-type {
    margin-bottom: 25px;
  }

  ul.meal-type li {
    font-size: 14px;
    margin-left: 5px;
  }

  /* Food Menu Style Two */
  .nav.nav-tabs.food-menu-nav.style-two::before {
    height: 100%;
    top: 0;
    right: -50%;
  }

  .nav.nav-tabs.food-menu-nav.style-two::after {
    height: 100%;
    top: 0;
    right: -50%;
  }

  .nav.nav-tabs.food-menu-nav.style-two {
    padding: 120px 0;
    display: block;
  }

  .food-menu-style-two-area {
    padding-top: 0;
  }

  .shape-overlay img {
    height: 150px;
  }

  .shape-overlay {
    bottom: -70px;
  }

  /* Food Menu Two */
  .food-menus-item .thumb {
    width: 100%;
    height: 450px;
    margin-bottom: 50px;
  }

  .food-menus-item .thumb .discount-badge {
    right: auto;
    left: 50px;
    top: auto;
    bottom: -50px;
  }

  .food-menus-item .info {
    padding: 0;
  }

  .food-menus-item {
    margin-top: 50px;
  }

  .food-menus-item:nth-child(2n) .info {
    padding: 0;
  }

  /* Food Category */
  .reservation-form.light {
    margin: 0;
    top: 0;
    margin-top: 140px;
  }

  h2.flex-title {
    padding-right: 0;
  }

  .food-swiper-nav {
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .food-cat-carousel.swiper .swiper-wrapper {
    padding-bottom: 80px;
  }

  /* Food Delivary */
  .deliverya-process-area .shape {
    display: none;
  }

  /* Why Choose Us */
  .choose-us-style-one::before,
  .choose-us-style-one::after {
    display: none;
  }

  .choose-us-style-one .item {
    border: 1px solid #cccccc;
    padding: 50px 37px;
    border-radius: 30px;
  }

  .choose-us-style-one-items {
    overflow: inherit;
  }

  .choose-us-style-one {
    border: none !important;
    margin-top: 30px;
    padding: 0 15px;
  }

  .choose-us-style-one:first-child,
  .choose-us-style-one:nth-child(2) {
    margin-top: 0;
  }

  /* Best Deal */
  .deal-style-one-items {
    text-align: center;
  }

  .deal-thumb img:first-child,
  .deal-thumb img:nth-child(3) {
    display: none;
  }

  .deal-thumb img:nth-child(2) {
    max-width: 100%;
    top: 0;
    margin: 0;
    transform: none;
    position: relative;
    padding: 0;
  }

  .deal-thumb .offer-badge {
    left: auto;
    top: auto;
    bottom: 0;
    right: 0;
  }

  .deal-thumb {
    margin: 0;
    margin-bottom: 50px;
  }

  /* Download App */
  .download-app-items {
    text-align: center;
  }

  .download-app-thumb {
    margin-bottom: 40px;
  }

  .download-app-items .d-flex {
    justify-content: center;
  }

  /* Opening Hours */

  .opening-hours-thumb .mbYTP_wrapper {
    height: 100%;
    max-width: 100% !important;
  }

  .opening-hour-items .text-fixed {
    display: none;
  }

  .opening-hours-thumb.video-bg-live {
    margin: 0;
    margin-bottom: 50px;
  }

  .opening-hours-info {
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: inherit;
  }

  .bg-dark .opening-hours-info {
    background: transparent;
  }

  .bg-dark ul.opening-hours-table h6 {
    background: var(--dark);
  }

  .bg-dark ul.opening-hours-table li span {
    background: var(--dark);
  }

  .opening-hour-items .shape {
    right: 0;
  }

  .opening-hour-items .shape img {
    height: 200px;
  }

  /* Testimonial */
  .testimonial-carousel.swiper
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    right: auto;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .testimonial-style-one {
    padding-bottom: 50px;
    text-align: center;
  }

  .testimonial-style-one p {
    font-size: 20px;
  }

  .testimonial-thumb {
    margin-bottom: 90px;
  }

  .testimonial-thumb img:nth-child(2) {
    position: absolute;
    right: auto;
    left: 100px;
    bottom: 50px;
    top: auto;
  }

  .testimonial-thumb img:nth-child(3) {
    position: absolute;
    right: auto;
    left: 50%;
    top: auto;
    bottom: -50px;
    transform: translateX(-50%);
    height: 120px;
  }

  .testimonial-thumb img:nth-child(4) {
    position: absolute;
    right: 100px;
    bottom: 50px;
  }

  .testimonial-thumb img {
    height: 100px;
  }

  .testimonial-style-one .rating {
    justify-content: center;
  }

  /* Reservation */
  .reservation-info {
    margin-bottom: 50px;
  }

  /* Chef Single */
  .chef-single-area .right-info .social {
    display: block;
  }

  .chef-single-area .chef-content-top {
    bottom: 0;
    margin: 0;
  }

  .chef-single-area .chef-content-top .thumb {
    margin-bottom: 30px;
  }

  .chef-single-area .chef-content-top .right-info p {
    padding: 0;
  }

  .chef-single-area .right-info > ul li i {
    min-width: 40px;
  }

  .chef-single-area .bottom-info .skill-items {
    padding: 0;
    margin-top: 50px;
  }

  /* Contact */
  .contact-style-one-info ul {
    grid-template-columns: 1fr;
  }

  .google-maps iframe {
    height: 450px;
  }

  /* Footer */
  .footer-bottom .col-lg-6 {
    width: 50%;
  }

  .footer-bottom-two {
    text-align: center;
    padding: 25px 0;
  }

  .footer-bottom-two .text-end {
    text-align: center !important;
  }

  .footer-bottom-two .text-end ul li {
    margin: 0 15px;
    margin-top: 5px;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .shape-overlay {
    display: none;
  }

  .heading {
    margin-top: 0;
    line-height: 1;
  }

  /* Topbar */
  .top-bar-area {
    display: none;
    background: var(--dark) !important;
  }

  .top-bar-area .item-flex {
    justify-content: center;
  }

  .top-bar-area .text-end {
    margin-top: 10px;
    display: none;
  }

  .top-bar-area {
    padding: 20px 0;
  }

  .topbar-two-items .logo {
    display: none;
  }

  .top-bar-style-two {
    padding: 15px 0;
    display: none;
  }

  .topbar-two-items {
    justify-content: center;
  }

  .topbar-two-items .topbar-info {
    margin: 0 25px;
  }

  .color-style-two .sub-title::before,
  .color-style-two .sub-title::after {
    background-size: 70px;
    display: none;
  }

  .sub-heading::before {
    display: none;
  }

  .sub-heading {
    margin-bottom: 20px;
    padding-right: 0;
  }

  .sub-title::before,
  .sub-title::after {
    display: none;
  }

  /* Banner */
  .banner-style-one h2 {
    font-size: 40px;
  }

  .banner-area.banner-style-one .content {
    padding: 80px 0;
  }

  .banner-area.banner-style-one .video-play-button {
    display: none;
  }

  .banner-area.banner-style-two .content {
    padding: 80px 0;
  }

  .banner-area.banner-style-two .content h2 {
    font-size: 40px;
  }

  .banner-style-two p {
    font-size: 16px;
  }

  .banner-style-two h4::before,
  .banner-style-two h4::after {
    display: none;
  }

  .banner-area.banner-style-two .banner-shap {
    display: none;
  }

  /* Banner Three */
  .banner-style-three-content h2 {
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 20px;
    margin-top: -10px;
  }

  ul.list-quality li {
    font-size: 18px;
  }

  .banner-style-three-content,
  .banner-style-three-content div {
    height: auto;
  }

  .banner-style-three-content {
    padding: 120px 0;
  }

  .banner-style-three-area {
    height: auto;
  }

  /* Banner Four */
  .banner-style-four-content h2 {
    font-size: 40px;
  }

  .banner-style-four-area,
  .banner-style-four-area div {
    height: auto;
  }

  .banner-style-four-content {
    padding: 80px 0;
  }

  /* Banner Five */
  .banner-area.banner-style-five {
    padding: 80px 0;
  }

  .banner-style-five h1 {
    font-size: 65px;
  }

  .banner-style-five h3 {
    font-size: 28px;
  }

  .banner-style-five .text-end {
    text-align: center !important;
  }

  .banner-style-five .bottom {
    text-align: center;
  }

  /* About */
  .about-style-one-info {
    margin: 0;
    position: inherit;
    padding: 0;
    box-shadow: none;
  }

  .bg-dark .about-style-one-info {
    background: transparent;
  }

  .about-thumb {
    position: inherit;
    min-height: 300px;
    width: 100%;
    grid-template-columns: 1fr;
    top: 0;
    margin-bottom: 30px;
  }

  .about-thumb .item:nth-child(2) {
    display: none;
  }

  .about-thumb .item {
    width: 100%;
    height: 100%;
    background-position: top center;
  }

  .about-style-one-info img {
    display: none;
  }

  .about-style-one-area {
    margin-bottom: 0;
    padding-top: 0;
  }

  /* About Style Two */
  .about-style-thumb-box > img {
    display: none;
  }

  .about-style-two-thumb::after {
    height: 100%;
    width: 100%;
  }

  .about-style-two-thumb {
    margin-bottom: 100px;
  }

  .about-style-thumb-box .curve-text {
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
  }

  /* About Style Three */
  .thumb-style-two img:first-child {
    padding: 10px;
  }

  .thumb-style-two {
    margin-bottom: 40px;
  }

  ul.card-info {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  /* Top Feature */
  .top-feature-style-one-area::after {
    width: 100%;
    height: 50%;
    top: auto;
    bottom: 0;
  }

  .top-feature-style-one-thumb img {
    margin: 30px 0;
  }

  /* Feature */
  .feature-style-two-items {
    margin-top: 40px;
    grid-template-columns: 1fr;
  }

  .opening-hours-hightlight ul li {
    font-size: 16px;
  }

  /* Menu Type */
  .menu-type-single {
    margin-top: 60px;
  }

  .menu-type-single:first-child {
    margin-top: 0;
  }

  .color-style-two .nav.nav-tabs.food-menu-nav.style-three button::before,
  .color-style-two .nav.nav-tabs.food-menu-nav.style-three button::after {
    display: none;
  }

  .nav.nav-tabs.food-menu-nav.style-three.four button {
    padding: 30px;
    border: 1px solid #cccccc;
    width: 100%;
  }

  .nav.nav-tabs.food-menu-nav.style-three.four button.active {
    border-color: var(--color-secondary);
  }

  .nav.nav-tabs.food-menu-nav.style-three.four {
    margin-bottom: 30px;
    width: 100%;
  }

  .tab-content.food-style-four-content {
    padding: 50px 30px;
  }

  .tab-content.food-style-four-content ul.meal-items li:first-child {
    border-top: none;
    padding-top: 0;
  }

  .food-menu-style-four-items .animate-up-down {
    display: none;
  }

  .menu-type-item .info {
    padding: 100px 30px;
    padding-top: 120px;
    padding-bottom: 50px;
  }

  /* About Three */
  .about-style-three-thumb {
    padding-right: 30px;
    padding-bottom: 30px;
    margin-bottom: 40px;
  }

  .about-style-three-thumb::after {
    width: 10px;
  }

  .item-grid-two-colum {
    grid-template-columns: 1fr;
  }

  /* Why Choose Us */
  .choose-us-style-one::before,
  .choose-us-style-one::after {
    display: none;
  }

  .choose-us-style-one .item {
    border: 1px solid #cccccc;
    padding: 50px 37px;
    border-radius: 30px;
  }

  .choose-us-style-one-items {
    overflow: inherit;
  }

  .choose-us-style-one {
    border: none !important;
    margin-top: 30px;
    padding: 0 15px;
  }

  .choose-us-style-one:first-child {
    margin-top: 0;
  }

  .reservation-area.mb-120 {
    margin-bottom: 60px;
  }

  /* Food Category */
  .reservation-form.light {
    margin: 0;
    top: 0;
    margin-top: 100px;
  }

  h2.flex-title {
    padding-right: 0;
  }

  .food-swiper-nav {
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .food-cat-carousel.swiper .swiper-wrapper {
    padding-bottom: 80px;
  }

  /* Offer Product */
  .offer-product-thumb {
    padding: 0;
  }

  .offer-product-thumb img {
    margin-bottom: 30px;
  }

  .food-quick-info {
    position: inherit;
    right: 0;
    left: 0;
    margin-top: 20px;
  }

  .food-quick-info::after {
    left: 50%;
    top: -30px;
    transform: rotate(90deg);
    margin-left: -15px;
  }

  .offer-product-info {
    margin-top: 50px;
    text-align: center;
  }

  /* Best Deal */
  .deal-style-one-items {
    padding: 50px 37px;
    text-align: center;
  }

  .deal-thumb img:first-child,
  .deal-thumb img:nth-child(3) {
    display: none;
  }

  .deal-thumb img:nth-child(2) {
    max-width: 100%;
    top: 0;
    margin: 0;
    transform: none;
    position: relative;
    margin-bottom: 70px;
    padding: 0;
  }

  .deal-thumb .offer-badge {
    left: 50%;
    top: auto;
    bottom: 0;
    transform: translateX(-50%);
  }

  .deal-thumb {
    margin: 0;
    margin-bottom: 30px;
  }

  .deal-info h2 {
    font-size: 36px;
    line-height: 1.2;
  }

  /* Fun Factor */
  .fun-facts-area .item .fun-fact {
    display: block;
    height: 320px;
    width: 320px;
    margin: 20px auto;
  }

  .fun-facts-area .item:nth-child(2n) .fun-fact::after {
    bottom: auto;
    top: -9px;
  }

  /* Service Style One Items */
  .services-swiper-nav {
    top: auto;
    bottom: 0;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .services-style-one-items .swiper-wrapper {
    padding-bottom: 80px;
  }

  /* Download App */
  .download-app-items {
    padding: 50px;
    text-align: center;
  }

  .download-app-thumb {
    margin-bottom: 40px;
  }

  .download-app-items .d-flex {
    justify-content: center;
  }

  /* Food Menu */
  .nav.nav-tabs.food-menu-nav {
    border: none;
    border-radius: inherit;
  }

  .nav.nav-tabs.food-menu-nav button {
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin: 0 7px;
    margin-bottom: 15px;
  }

  .food-menu-tab-content .discount-badge {
    display: none;
  }

  .tab-content.food-menu-tab-content .info {
    padding: 50px;
  }

  ul.meal-items li .content .top::before,
  ul.meal-items li .content .top::after {
    display: none;
  }

  ul.meal-items li .content .top h4 {
    position: inherit;
    margin-bottom: 10px;
    font-size: 18px;
  }

  ul.meal-items li .content .top .price {
    font-size: 18px;
  }

  ul.meal-items li .content .bottom {
    display: block;
  }

  ul.meal-items li .content .bottom .right {
    text-align: left;
  }

  ul.meal-items li {
    align-items: inherit;
  }

  ul.meal-items li .thumbnail img {
    min-width: 60px;
  }

  ul.meal-items li .content .top {
    align-items: start;
    margin-bottom: 0;
  }

  .tab-content.food-menu-tab-content .thumb {
    height: 350px;
  }

  .food-menu-area::after {
    height: 55%;
  }

  ul.meal-type {
    margin-bottom: 25px;
  }

  ul.meal-type li {
    font-size: 14px;
    margin-left: 5px;
  }

  /* Food Menu Style Two */
  .nav.nav-tabs.food-menu-nav.style-two::before {
    height: 100%;
    top: 0;
    right: -50%;
  }

  .nav.nav-tabs.food-menu-nav.style-two::after {
    height: 100%;
    top: 0;
    right: -50%;
  }

  .nav.nav-tabs.food-menu-nav.style-two {
    padding: 60px 0;
    display: block;
  }

  .food-menu-style-two-area {
    padding-top: 0;
  }

  /* Food Menu Two */
  .food-menus-item .thumb {
    width: 100%;
    height: 350px;
    margin-bottom: 50px;
  }

  .food-menus-item .thumb .discount-badge {
    display: none;
  }

  .food-menus-item .info {
    padding: 0;
  }

  .food-menus-item {
    margin-top: 50px;
  }

  .food-menus-item:nth-child(2n) .info {
    padding: 0;
  }

  .shape-less .nav.nav-tabs.food-menu-nav {
    border: none;
    background: transparent;
  }

  .shape-less .nav.nav-tabs.food-menu-nav button {
    background: var(--white);
    color: var(--color-heading);
  }

  .shape-less .nav.nav-tabs.food-menu-nav button.active {
    color: var(--color-primary);
  }

  .bg-dark .shape-less .nav.nav-tabs.food-menu-nav button {
    background: var(--dark);
  }

  ul.meal-items li {
    border-top: 1px solid #e7e7e7;
    padding-top: 30px;
  }

  .tab-content.food-menu-tab-content ul.meal-items li {
    border-color: rgba(255, 255, 255, 0.3);
  }

  .bg-gray .tab-content.food-menu-tab-content ul.meal-items li {
    border-color: #cccccc;
  }

  /* Food Menu Three */
  .nav.nav-tabs.food-menu-nav.style-three button {
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: block;
    width: 100%;
  }

  .nav.nav-tabs.food-menu-nav.style-three {
    border: none;
    padding: 0;
    margin-bottom: 30px;
    width: 100%;
  }

  .nav.nav-tabs.food-menu-nav.style-three button.active {
    border-color: transparent;
  }

  /* Opening Hours */
  .opening-hour-items .text-fixed {
    display: none;
  }

  .opening-hours-thumb.video-bg-live {
    margin: 0;
    margin-bottom: 30px;
  }

  .opening-hours-info {
    margin: 0;
    padding: 0;
    background: transparent;
    box-shadow: inherit;
  }

  .bg-dark .opening-hours-info {
    background: transparent;
  }

  .bg-dark ul.opening-hours-table h6 {
    background: var(--dark);
  }

  .bg-dark ul.opening-hours-table li span {
    background: var(--dark);
  }

  .opening-hour-items .shape {
    right: 0;
  }

  .opening-hour-items .shape img {
    height: auto;
  }

  /* Testimonial */
  .testimonial-carousel.swiper
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    right: auto;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .testimonial-style-one {
    padding-bottom: 50px;
    text-align: center;
  }

  .testimonial-style-one p {
    font-size: 20px;
  }

  .testimonial-thumb {
    margin-bottom: 40px;
  }

  .testimonial-thumb img:nth-child(2) {
    position: absolute;
    right: auto;
    left: 8%;
    bottom: 5%;
    top: auto;
    display: none;
  }

  .testimonial-thumb img:nth-child(3) {
    position: absolute;
    right: auto;
    left: 50%;
    top: auto;
    bottom: -30px;
    transform: translateX(-50%);
    height: 80px;
    display: none;
  }

  .testimonial-thumb img:nth-child(4) {
    position: absolute;
    right: 8%;
    bottom: auto;
    bottom: 5%;
    display: none;
  }

  .testimonial-thumb img {
    height: 70px;
  }

  .testimonial-style-one .rating {
    justify-content: center;
  }

  /* Chef */

  .chef-area.default-padding {
    padding-bottom: 0;
  }

  /* Blog */
  .home-blog-style-one-item .content {
    padding: 0;
    top: 0;
    margin: 0;
  }

  .home-blog-style-one-item {
    margin-bottom: 30px;
  }

  .home-blog-style-one-item .info {
    padding: 30px;
  }

  .grid-colum-3 .home-blog-style-one-item .info {
    padding: 30px;
  }

  .home-blog-style-one-item .info .date {
    top: -110px;
    right: auto;
  }

  .home-blog-style-one-item .info .title {
    padding: 0;
    font-size: 24px;
  }

  .home-blog-style-one-item .info ul.blog-meta {
    margin-bottom: 15px;
  }

  /* Chef Single */
  .chef-single-area .right-info .social {
    display: block;
  }

  .chef-single-area .right-info .social .share-link {
    margin-top: 30px;
    display: none;
  }

  .chef-single-area .chef-content-top {
    bottom: 0;
    margin: 0;
  }

  .chef-single-area .chef-content-top .thumb {
    margin-bottom: 30px;
  }

  .chef-single-area .chef-content-top .right-info p {
    padding: 0;
  }

  .chef-single-area .right-info > ul li i {
    min-width: 39px;
  }

  .chef-single-list {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }

  .chef-single-area .bottom-info .skill-items {
    padding: 0;
    margin-top: 30px;
  }

  .banner-style-one h4 {
    font-size: 20px;
  }

  /* Reservation */
  .reservation-info {
    margin-bottom: 40px;
  }

  /* Delivary Process */
  .deliverya-process-area .shape {
    display: none;
  }

  .delivary-projcess h2 {
    font-size: 36px;
  }

  /* Contact */
  .contact-style-one-info ul {
    grid-template-columns: 1fr;
  }

  .google-maps iframe {
    height: 350px;
  }

  /* Footer */
  .footer-bottom .text-end {
    text-align: center !important;
    margin-top: 16px;
    position: relative;
    z-index: 1;
  }

  .footer-logo {
    text-align: center;
  }

  footer {
    background: var(--dark);
  }

  .bg-dark footer {
    background: #363434 !important;
  }

  .footer-style-one::after {
    display: none;
  }

  .footer-style-one {
    padding: 0;
    padding-bottom: 50px;
  }

  .bg-dark .footer-style-one.bg-dark {
    background: transparent !important;
  }

  footer .about {
    padding: 0;
    border: none;
  }

  .footer-bottom::after {
    height: 100%;
  }

  .footer-bottom-shape {
    display: none;
  }

  .footer-bottom-two {
    text-align: center;
    padding: 25px 0;
  }

  .footer-bottom-two .text-end {
    text-align: center !important;
  }

  .footer-bottom-two .text-end ul li {
    margin: 0 15px;
    margin-top: 5px;
  }

  .footer-style-two .footer-top {
    margin: 0;
    text-align: left !important;
    display: none;
  }

  .footer-style-two .footer-top a {
    padding: 0;
    text-align: left;
  }

  .footer-top::after {
    display: none;
  }

  .footer-style-two {
    padding-top: 0;
  }
}

@media only screen and (max-width: 500px) {
  /* About */
  .author-info {
    display: block;
  }

  .author-details {
    margin-top: 30px;
    display: none;
  }

  /* Menu */

  ul.meal-items li {
    display: block;
  }

  ul.meal-items li .thumbnail {
    display: none;
  }

  .nav.nav-tabs.food-menu-nav button {
    margin: 0;
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .nav.nav-tabs.food-menu-nav {
    display: block;
  }

  .tab-content.food-menu-tab-content .info {
    padding: 50px 30px;
  }

  /* Opening Hours */
  ul.opening-hours-table li {
    display: block;
    margin-top: 20px;
  }

  ul.opening-hours-table li span {
    padding: 0;
    margin-top: 5px;
    display: block;
  }

  ul.opening-hours-table li::after {
    display: none;
  }

  /* Reservation */
  .reservation-form {
    padding: 50px 30px;
  }

  .reservation-time li {
    border: none;
    display: block;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
  }

  .services-style-one .info {
    padding: 50px 25px;
  }

  .services-style-one ul li {
    font-size: 14px;
  }

  .download-app-items .d-flex {
    display: block !important;
  }

  .download-app-items .d-flex a:nth-child(2) {
    margin-top: 15px;
  }

  /* Contact */
  .contact-form-style-one {
    padding: 50px 30px;
  }

  /* Error */
  .error-box h1 {
    font-size: 150px;
  }

  .error-box img {
    height: 120px;
  }
}

@media screen and (max-width: 400px) {
  .chef-thumb .info {
    left: 0;
    right: 0;
  }

  .chef-thumb {
    height: 260px;
    width: 260px;
  }

  .chef-thumb::after {
    height: 280px;
    width: 280px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* ============================================================== 
     # Preloader 
=================================================================== */

.restan-preloader {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9000;
}

.restan-preloader .animation-preloader {
  z-index: 1000;
  position: relative;
}

.restan-preloader .animation-preloader::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  height: 100%;
  width: 100%;
  background: url(../img/logo-icon.png);
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
}

.bg-dark .restan-preloader .animation-preloader::after {
  background: url(../img/logo-icon.png);
  background-repeat: no-repeat;
  background-position: center;
}

.restan-preloader .animation-preloader .spinner {
  -webkit-animation: spinner 1s infinite linear;
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 2px solid rgba(2, 109, 255, 0.3);
  border-top-color: var(--color-primary);
  height: 110px;
  margin: auto;
  width: 110px;
}

.bg-dark .restan-preloader .animation-preloader .spinner {
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-top-color: rgba(255, 255, 255, 1);
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(2):before {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(3):before {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(5):before {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(6):before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(7):before {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.restan-preloader
  .animation-preloader
  .txt-loading
  .letters-loading:nth-child(8):before {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.restan-preloader.dark .animation-preloader .spinner {
  border-color: rgba(255, 255, 255, 0.2);
  border-top-color: #fff;
}

.restan-preloader .loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  z-index: 1;
  pointer-events: none;
}

.restan-preloader .loader .row {
  height: 100%;
}

.restan-preloader .loader .loader-section {
  padding: 0px;
}

.restan-preloader .loader .loader-section .bg {
  background-color: var(--white);
  height: 100%;
  left: 0;
  width: 100%;
  -webkit-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
}

.bg-dark .restan-preloader .loader .loader-section .bg {
  background-color: var(--dark);
}

.restan-preloader .loader.dark_bg .loader-section .bg {
  background: #111339;
}

.restan-preloader.loaded .animation-preloader {
  opacity: 0;
  -webkit-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.restan-preloader.loaded .loader-section .bg {
  width: 0;
  -webkit-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  -o-transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
  transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@media screen and (max-width: 767px) {
  .restan-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .restan-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
}
