/* Root Variables */
:root {
  --font-default: "Lato", sans-serif;
  --font-heading: "Marcellus", cursive;
  --font-optional: "Dancing Script", cursive;
  --black: #000000;
  --dark: #1b1b1b;
  --dark-secondary: #262525;
  --white: #ffffff;
  --color-primary: #826a45;
  --color-secondary: #e7272d;
  --color-optional: #cf820a;
  --color-heading: #04000b;
  --color-paragraph: #666666;
  --box-shadow-primary: -1px 3px 10px 0 rgba(0, 0, 0, 0.6);
  --bg-gray: #ebe9e6;
  --bg-gradient: linear-gradient(45deg, var(--color-primary) 30%, #ffb800 100%);
}

/* Reset CSS */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  font-family: var(--font-default);
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
}

/* General Styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-heading);
  font-family: var(--font-heading);
  margin-bottom: 15px;
}

h1 {
  font-size: 48px;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

p {
  color: var(--color-paragraph);
  margin-bottom: 25px;
}

a {
  color: var(--color-heading);
  text-decoration: none;
  transition: color 0.35s ease-in-out;
}

a:hover {
  color: var(--color-primary);
}

button {
  padding: 13px 30px;
  background: #e7edf8;
  color: var(--white);
  border: none;
  border-radius: 7px;
  transition: background 0.35s ease-in-out;
}

button:hover {
  background: var(--color-primary);
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

.bg-cover {
  background-position: center center;
  background-size: cover;
}

.text-light {
  color: var(--white);
}

/* Navbar */
.navbar {
  background-color: var(--dark);
  color: var(--white);
  padding: 15px 0;
}

.navbar .navbar-brand img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-right: 20px;
}

.navbar .navbar-toggle {
  border: none;
  background: none;
  color: var(--white);
}

.overlay-screen {
  display: none;
}

/* Banner */
.banner-style-three-area {
  text-align: center;
  position: relative;
  padding: 100px 0;
  background-size: cover;
  background-position: center;
}

.banner-style-three-content {
  position: relative;
  z-index: 2;
}

.banner-style-three-content h2 {
  font-size: 48px;
  color: var(--white);
}

.list-quality {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding: 0;
  list-style: none;
}

.list-quality li {
  font-size: 20px;
  color: var(--white);
}

/* Features */
.feature-style-three-area {
  padding: 60px 0;
}

.reservation-form {
  padding: 30px;
  background: var(--white);
  box-shadow: var(--box-shadow-primary);
  border-radius: 5px;
}

.reservation-form h3 {
  margin-bottom: 20px;
}

.reservation-form .form-group {
  margin-bottom: 15px;
}

.reservation-form .form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--bg-gray);
  border-radius: 5px;
}

.reservation-form button {
  width: 100%;
}

/* Menu Slider */
.menu-type-area {
  padding: 60px 0;
  background: var(--bg-gray);
}

.menu-type-slider {
  position: relative;
}

.menu-type-item {
  text-align: center;
  padding: 20px;
}

.menu-type-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.menu-type-item .info {
  margin-top: 10px;
}

/* About */
.about-style-three-area {
  padding: 60px 0;
}

.about-style-three-thumb img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.about-style-three-info {
  padding: 30px;
}

.about-style-three-info h4 {
  margin-bottom: 10px;
}

.about-style-three-info h2 {
  margin-bottom: 20px;
}

/* Footer */
footer {
  background: var(--dark-secondary);
  color: var(--white);
  padding: 30px 0;
}

.footer-item {
  margin-bottom: 30px;
}

.footer-item h4 {
  margin-bottom: 20px;
}

.footer-item p {
  margin-bottom: 10px;
}

.footer-item .footer-social {
  display: flex;
  gap: 10px;
}

.footer-social li {
  list-style: none;
}

.footer-social a {
  color: var(--white);
}

.footer-bottom {
  background: var(--dark);
  color: var(--white);
  padding: 15px 0;
  text-align: center;
}
/* Toast Container Styles for Mobile */
.Toastify__toast-container {
  width: 100%;
  max-width: 400px;
  padding: 0;
}

.Toastify__toast {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.Toastify__toast-body {
  font-size: 14px;
}

/* Adjust Toast Position for Mobile */
@media (max-width: 600px) {
  .Toastify__toast-container {
    width: 90%;
    left: 5%;
    right: 5%;
    bottom: 10px;
  }
}
